import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Stack,
  MenuItem,
  useTheme,
  useMediaQuery,
  DialogActions,
  Button,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { AiFlowLogic, AiFlowVersionLogic } from 'src/api';
import Iconify from 'src/components/iconify';
import { SkeletonProductDetails } from 'src/components/skeleton';
import {
  renderCell,
  renderDateCell,
  renderFixedNumberCell,
} from 'src/components/table-columns/table-columns';
import AiFlowDialogsSkeleton from './AiFlowDialogsSkeleton';
import { useDispatch, useSelector } from 'src/redux/store';
import { useCallback, useEffect, useState } from 'react';
import { get } from 'lodash';
import {
  fetchAiFlowVersions,
  selectAiFlow,
  selectAiFlowItemBySequence,
  setSelectedAiFlowVersion,
} from 'src/redux/slices/aiflows';
import { usePopover } from 'src/components/custom-popover';
import MenuPopover from 'src/components/menu-popover';
import { useNavigate } from 'react-router';
import { PATH_MARKETING } from 'src/routes/paths';
import { useSnackbar } from 'src/components/snackbar';
import { useBoolean } from 'src/hooks/use-boolean';
import AiFlowNewVersionDialog from './AiFlowNewVersionDialog';
import { runAiFlow } from 'src/redux/slices/aiFlow-runs';

interface Props {
  onClose: () => void;
  open: boolean;
  aiFlow: AiFlowLogic;
}

export default function AiFlowVersionsDialog({ onClose, open, aiFlow }: Props) {
  const handleClose = () => {
    onClose();
  };
  const [selectedVersion, setSelectedVersion] = useState<AiFlowVersionLogic>();
  const selectedAiFlowVersion = useSelector((state) => state.aiFlows.selectedAiFlowVersion);
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const loading = useSelector((state) => state.aiFlows.loadingAiFlowVersions);
  const actionsPopover = usePopover();
  const navigate = useNavigate();
  useEffect(() => {
    if (open && aiFlow && aiFlow.id) {
      dispatch(fetchAiFlowVersions(aiFlow.id ?? 0));
    }
  }, [aiFlow, open, dispatch]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      resizable: true,
      renderCell,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 150,
      resizable: true,
      renderCell,
    },
    {
      field: 'version',
      headerName: 'Version',
      width: 150,
      resizable: true,
      renderCell: (params) => renderFixedNumberCell(params, 1),
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      width: 150,
      resizable: true,
      renderCell: renderDateCell,
    },
    {
      field: 'lastSaved',
      headerName: 'Last Updated',
      width: 150,
      resizable: true,
      renderCell: renderDateCell,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 60,
      renderCell: (params: any) => (
        <>
          <IconButton
            onClick={(event) => {
              setSelectedVersion(params.row);
              // setSelectedAiFlowId(params.row.aiFlowId);
              // setSelectedAiFlowRunId(params.row.id);
              actionsPopover.setOpen(event.currentTarget);
            }}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </>
      ),
    },
  ];



  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
  const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));

  let gridHeight = `${window.innerHeight * 0.5}px`; // default for small screens
  if (matchesMD) gridHeight = `${window.innerHeight * 0.55}px`; // md screens
  if (matchesXL) gridHeight = `${window.innerHeight * 0.8}px`; // xl screens
  const openNewFlowVersionDialog = useBoolean(false);

  const handleRowClick = useCallback(
    (params: any) => {
      console.log('params', params);
      setSelectedRow(params.id);
    },
    []
  );

  useEffect(() => {
    if (selectedAiFlowVersion){
      console.log('selectedAiFlowVersion', selectedAiFlowVersion);
      setSelectedRow(selectedAiFlowVersion?.id as number);
    }
  }, [selectedAiFlowVersion]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle sx={{ pb: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <span>Versions</span>
          <IconButton onClick={handleClose}>
            <Iconify icon="material-symbols:close" width={20} height={20} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ typography: 'body2', overflow: 'hidden', p: 3 }}>
        {loading ? (
          <AiFlowDialogsSkeleton />
        ) : (
          <DataGrid
            rows={aiFlow?.aiFlowVersions ?? []}
            columns={columns}
            rowSelectionModel={selectedRow ? [selectedRow] : []}
            sx={{
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
                '&.Mui-selected': {
                  backgroundColor: 'rgba(0, 0, 0, 0.09)', // Highlight color for selected row
                },
                // '& .MuiDataGrid-virtualScroller': {
                //   // Target the virtual scroller element
                //   maxHeight: gridHeight,
                // },
              },
            }}
            onRowClick={handleRowClick}
            style={{ maxHeight: gridHeight }}
            //   rowSelectionModel={selectedRow ? [selectedRow] : []}
            pagination
            // Other props...

            onRowModesModelChange={(newModel: any) => {
              // setSelectedAiFlowsId(newModel as number[]);
            }}
          />
        )}
        <MenuPopover
          open={actionsPopover.open}
          onClose={actionsPopover.onClose}
          arrow="right-top"
          sx={{ width: 150 }}
        >
          <MenuItem
            onClick={() => {
              dispatch(
                runAiFlow(
                  aiFlow as AiFlowLogic,
                  selectedVersion?.id as number,
                  null as any,
                  enqueueSnackbar
                )
              );
              actionsPopover.onClose();
            }}
            sx={{ color: 'success.main' }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Iconify icon="codicon:debug-start" />
              <span>Run</span>
            </Stack>
          </MenuItem>
          <MenuItem
            onClick={() => {
              const mixed = { ...aiFlow, ...selectedVersion };
              //     dispatch(selectAiFlow(mixed));
              dispatch(selectAiFlowItemBySequence(null));
              dispatch(setSelectedAiFlowVersion(selectedVersion));
              if (!selectedVersion?.version) {
                return;
              }
              navigate(
                PATH_MARKETING.pages.editAiFlowVersion(
                  aiFlow?.id as number,
                  selectedVersion.version.toFixed(1).toString()
                )
              );
              actionsPopover.onClose();
              openNewFlowVersionDialog.onFalse();
              onClose();
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Iconify icon="eva:edit-fill" />
              <span>Edit</span>
            </Stack>
          </MenuItem>
        </MenuPopover>
      </DialogContent>
      <DialogActions>
        <Button onClick={openNewFlowVersionDialog.onTrue}>New Version</Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
      <AiFlowNewVersionDialog
        open={openNewFlowVersionDialog.value}
        onClose={openNewFlowVersionDialog.onFalse}
      />
    </Dialog>
  );
}
