import { Card } from '@mui/material';
import { Box } from '@mui/system';
import { useState, useRef, useEffect } from 'react';
import { AgentApi, AgentLogic, AssistantInfo, DeployedAgentLogic, GenerativeAiApi } from 'src/api';
import Scrollbar from 'src/components/scrollbar';
import { useSelector } from 'src/redux/store';
import ChatInput from '../ChatInput';
import ChatInputPublic from './ChatInputPublic';
import ChatMessagePublic from './ChatMessagePublic';
import { useNavigate } from 'react-router';
import Page404 from 'src/pages/Page404';
import AgentNotFound from './AgentNotFound';
import { ShareButton } from 'src/common/share/ShareButton';
import { darkModeColor } from './DarkModeColor';
import Smartlook from 'smartlook-client';

interface Params {
  deployedAgent?: DeployedAgentLogic;
}

interface Message {
  sender: 'user' | 'assistant';
  content: string;
}

export default function AgentChatUiPublic({ deployedAgent }: Params) {
  const [layout, setLayout] = useState<string>('fullwidth');
  const [selectedAgent, setSelectedAgent] = useState<DeployedAgentLogic>();
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [threadId, setThreadId] = useState<string>();
  const [agentNotFound, setAgentNotFound] = useState<boolean>(false);
  const [backgroundColor, setBackgroundColor] = useState<string>('#F7F7F8');
  const [assistantInfo, setAssistantInfo] = useState<AssistantInfo>();
  const [messages, setMessages] = useState<Message[]>([
    {
      sender: 'assistant',
      content: 'How can I assist you in finding information or solving a problem today?',
    },
  ]);
  if (process.env.NODE_ENV === 'production') {
    // Smartlook.init(process.env.SMARTLOOK_KEY);
    Smartlook.init('c86e9beab40a477573dc7c2f257819be7d72f6a6');
  }
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  useEffect(() => {
    if (deployedAgent) {
      setSelectedAgent(deployedAgent);
    }
  }, [deployedAgent]);

  useEffect(() => {
    const stopPropagation = (e: Event) => {
      e.stopPropagation(); // Prevent scroll event from bubbling up
    };

    window.addEventListener('wheel', stopPropagation, { passive: false });
    window.addEventListener('touchmove', stopPropagation, { passive: false });

    return () => {
      window.removeEventListener('wheel', stopPropagation);
      window.removeEventListener('touchmove', stopPropagation);
    };
  }, []);

  useEffect(() => {
    if (messages.length > 1) {
      scrollToBottom();
    }
  }, [messages, isLoading]); // Scroll when messages change or loading state changes

  const handleSend = async (message: string) => {
    const newMessages = [...messages, { sender: 'user' as const, content: message }];
    setMessages(newMessages);
    setIsLoading(true);
    const generativeAiApi = new GenerativeAiApi();
    const response = await generativeAiApi.apiGenerativeAiPublicChatPost({
      input: message,
      agentId: selectedAgent?.agentId,
      threadId,
      configurations: selectedAgent?.engineConfigurations,
    });
    setThreadId(response.data.assistantInfo?.threadId as string);
    setMessages([
      ...newMessages,
      {
        sender: 'assistant' as const,
        content: response.data.message as string,
      },
    ]);
    setIsLoading(false);

    // setTimeout(() => {
    //   setMessages([
    //     ...newMessages,
    //     {
    //       sender: 'assistant' as const,
    //       content: 'This is a demo response. The UI is just for demonstration purposes.',
    //     },
    //   ]);
    //   setIsLoading(false);
    // }, 9000);
  };

  const calculateNewScrollbarHeightWithSuggestions = () => {
    if (!selectedAgent?.uiConfigurations?.agentChatUiConfigurations?.enableSmartSuggestions) {
      return 'calc(100vh - 200px)';
    }
    let defaultHeightDiminisher = 180;
    const suggestions =
      selectedAgent?.uiConfigurations?.agentChatUiConfigurations?.smartSuggestions;
    const suggestionsHeight = suggestions ? suggestions.length * 40 : 0;
    defaultHeightDiminisher += suggestionsHeight;
    return `calc(100vh - ${defaultHeightDiminisher}px)`;
  };

  useEffect(() => {
    if (selectedAgent?.uiConfigurations?.agentChatUiConfigurations) {
      setLayout(selectedAgent.uiConfigurations.agentChatUiConfigurations?.layout ?? 'fullwidth');
      setDarkMode(selectedAgent.uiConfigurations.agentChatUiConfigurations?.darkMode ?? false);
      setBackgroundColor(
        selectedAgent.uiConfigurations.agentChatUiConfigurations?.backgroundColor ?? '#F7F7F8'
      );
    }
  }, [selectedAgent]);

  const getLayoutMaxWidth = () => {
    switch (layout) {
      case 'wide':
        return '800px';
      case 'narrow':
        return '36rem';
      case 'centered':
        return '48rem';
      case 'fullwidth':
        return '100%';
      default:
        return '48rem';
    }
  };

  const getLayoutMx = () => {
    switch (layout) {
      case 'centered':
        return 'auto';
      case 'narrow':
        return 'auto';
      case 'fullwidth':
        return '0';
      default:
        return 'auto';
    }
  };

  return agentNotFound ? (
    <AgentNotFound />
  ) : (
    <>
      <Box sx={{ position: 'fixed', top: 10, right: 20, zIndex: 10 }}>
        <ShareButton darkMode={darkMode} />
      </Box>
      <Card
        sx={{
          backgroundColor: darkMode ? darkModeColor : backgroundColor,
          p: 1,
          margin: '0px !important',
          //   minHeight: { md: 550, xl: 680 },
          //   maxHeight: { md: 550, xl: 680 },
        }}
      >
        <Box sx={{ position: 'relative', margin: '0px !important' }}>
          <Box
            sx={{
              //   minHeight: '100vh',
              //   bgcolor: '#F7F7F8',
              //     pb: '160px',
              backgroundColor: darkMode ? darkModeColor : backgroundColor,
              maxWidth: getLayoutMaxWidth(),
              mx: getLayoutMx(),
              overflowX: 'hidden !important',
              overflowY: 'hidden !important',
              minHeight: 'calc(100vh - 170px)',
              maxHeight: 'calc(100vh - 170px)',
              //   minHeight: 200,

              //   maxHeight: 500,
              //      width: getLayoutMaxWidth,
              //      minWidth: getLayoutMaxWidth,
            }}
          >
            <Scrollbar
              sx={{
                minHeight: 300,
                maxHeight: calculateNewScrollbarHeightWithSuggestions(),
                overflowX: 'hidden !important',
                backgroundColor: darkMode ? darkModeColor : backgroundColor,
              }}
            >
              <Box
                sx={{
                  backgroundColor: darkMode ? darkModeColor : backgroundColor,
                  minWidth: '100%',
                  minHeight: '100%',
                  p: 1,
                }}
              >
                {messages.map((message, index) => (
                  <ChatMessagePublic
                    key={index}
                    {...message}
                    selectedAgent={selectedAgent as DeployedAgentLogic}
                  />
                ))}
                {isLoading && <ChatMessagePublic sender="assistant" content="Loading..." />}
                <div ref={messagesEndRef} />
              </Box>
            </Scrollbar>
            <ChatInputPublic
              onSend={handleSend}
              disabled={isLoading}
              selectedAgent={selectedAgent as DeployedAgentLogic}
            />
          </Box>
        </Box>
      </Card>
    </>
  );
}
