import { Divider, Link, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { updateTempSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';
import ConnectionSelect from 'src/components/connection/ConnectionSelect';
import ModelProviderSelect from 'src/components/model/ModelProviderSelect';
import ModelSelect from 'src/components/model/ModelSelect';
import { ModelType } from 'src/common/constants/llm-models.constants';
import PromptEditor from 'src/components/prompt-editor/PromptEditor';

export default function AiFlowJudgeActionSetup() {
  const dispatch = useDispatch();

  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const [selectedConnectionId, setConnectionId] = useState<number>(0);
  const [selectedModelProvider, setModelProvider] = useState<string>('OpenAI' as string);
  const [selectedModel, setSelectedModel] = useState<string>(ModelType.gpt_4_o_mini);
  const [judgePrompt, setJudgePrompt] = useState<string>();
  const agentSetupMode = useSelector((state) => state.appStates.agentSetupMode);

  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.inputData?.actionInputData?.llmInputData?.judgeInputData) {
        setConnectionId(
          selectedItem.inputData?.actionInputData?.llmInputData?.judgeInputData
            ?.connectionId as number
        );
        setModelProvider(
          (selectedItem.inputData?.actionInputData?.llmInputData?.judgeInputData
            ?.modelProvider as string) ?? 'OpenAI'
        );
        setSelectedModel(
          (selectedItem.inputData?.actionInputData?.llmInputData?.judgeInputData
            ?.model as string) ?? ModelType.gpt_4_o_mini
        );
        setJudgePrompt(
          selectedItem.inputData?.actionInputData?.llmInputData?.judgeInputData
            ?.judgePrompt as string
        );
      }
    }
  }, [selectedItem]);

  return (
    <>
      <Stack spacing={4}>
        <ModelProviderSelect
          selectedModelInput={selectedModel}
          onModelProviderSelect={(modelProvider: string) => {
            setModelProvider(modelProvider);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  llmInputData: {
                    ...selectedItem?.inputData?.actionInputData?.llmInputData,
                    judgeInputData: {
                      ...selectedItem?.inputData?.actionInputData?.llmInputData?.judgeInputData,
                      model: modelProvider,
                    },
                  },
                },
              },
            };
            dispatch(updateTempSelectedAiFlowItem(updatedItem));
          }}
        />
        <ModelSelect
          marginTop={14}
          selectedModelProviderInput={selectedModelProvider}
          selectedConnectionInput={selectedConnectionId}
          selectedModelInput={selectedModel}
          onModeSelect={(model: string) => {
            setSelectedModel(model);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  llmInputData: {
                    ...selectedItem?.inputData?.actionInputData?.llmInputData,
                    judgeInputData: {
                      ...selectedItem?.inputData?.actionInputData?.llmInputData?.judgeInputData,
                      model,
                    },
                  },
                },
              },
            };
            dispatch(updateTempSelectedAiFlowItem(updatedItem));
          }}
        />

        <ConnectionSelect
          disabled={runsMode}
          px={1}
          selectedIntegration="OpenAI"
          selectedConnectionInput={selectedConnectionId}
          onConnectionSelect={(connectionId: number) => {
            setConnectionId(connectionId);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  llmInputData: {
                    ...selectedItem?.inputData?.actionInputData?.llmInputData,
                    judgeInputData: {
                      ...selectedItem?.inputData?.actionInputData?.llmInputData?.judgeInputData,
                      connectionId,
                    },
                  },
                },
              },
            };
            dispatch(updateTempSelectedAiFlowItem(updatedItem));
          }}
        />
        <Paper
          key=""
          variant="outlined"
          sx={{
            py: 1.5,
            mx: 1,
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            flexGrow: 1,
            height: '100%',
          }}
        >
          <Stack direction="row" justifyContent="center">
            <Typography variant="subtitle2" sx={{ textAlign: 'center', paddingBottom: 1, ml: 1 }}>
              Judge Prompt
            </Typography>
          </Stack>
          <Divider sx={{ mb: 1 }} />
          <PromptEditor
          id = "judgePrompt"
            height={180}
            promptInput={judgePrompt as string}
            readonly={runsMode || agentSetupMode}
            setPromptInput={(prompt: string) => {
              setJudgePrompt(prompt);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    llmInputData: {
                      ...selectedItem?.inputData?.actionInputData?.llmInputData,
                      judgeInputData: {
                        ...selectedItem?.inputData?.actionInputData?.llmInputData?.judgeInputData,
                        judgePrompt: prompt,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
          />
        </Paper>
      </Stack>
    </>
  );
}
