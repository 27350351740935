import { createSlice, Dispatch } from '@reduxjs/toolkit';
// @types
import { LanguageApi } from 'src/api';
import { ILanguageState } from 'src/@types/language';

// ----------------------------------------------------------------------

const initialState: ILanguageState = {
  languages: [],
  loaded: false,
  error: null,
};

const slice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    // START LOADING
    fetchSupportedLanguagesSuccess(state, action) {
      state.languages = action.payload;
      state.loaded = true;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function fetchSupportedLanguages() {
  return async (dispatch: Dispatch) => {
    try {
      const languageApi = new LanguageApi();
      const response = await languageApi.apiLanguageSupportedLanguagesGet();
      dispatch(slice.actions.fetchSupportedLanguagesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
