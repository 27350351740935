import _ from 'lodash';
import { useEffect, useState } from 'react';
import { ActionInfo, AiFlowItemLogic, RunOutput, RunOutputInfo } from 'src/api';
import { useSelector } from 'src/redux/store';
import OutputCard from 'src/components/output/OutputCard';
import { Box, IconButton, Pagination, Stack, Tooltip } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import FullPageDialog from 'src/common/modals/FullPageDialog';

interface Params {
  fullScreen?: boolean;
}

export default function AiFlowItemOutputView({ fullScreen }: Params) {
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const [lastSelectedItem, setLastSelectedItem] = useState<AiFlowItemLogic>();
  const [selectedAction, setSelectedAction] = useState<ActionInfo>();
  const [status, setStatus] = useState<string>('');
  const openFullScreen = useBoolean(false);
  const [file, setFile] = useState<boolean>(false);
  const [outputs, setOutputs] = useState<string[]>([]);
  const [runOutput, setRunOutput] = useState<RunOutput>();
  const [runOutputId, setRunOutputId] = useState<number>();
  const [outputIndex, setOutputIndex] = useState<number>(0);
  const actions = useSelector((state) => state.aiFlows.actions);
  const aiFlowRunsOutputs = useSelector((state) => state.aiFlowsRuns.aiFlowRunsOutputs);
  const selectedAiFlowRun = useSelector((state) => state.aiFlowsRuns.selectedAiFlowRun);

  useEffect(() => {
    if (selectedItem) {
      if (!_.isEqual(selectedItem, lastSelectedItem)) {
        setLastSelectedItem(selectedItem);
      }
      if (selectedItem.header) {
        const selectedItemHeader = selectedItem.header;
        const tmpAction = actions.find((a) => a.header === selectedItemHeader);

        setSelectedAction(tmpAction);
      }
    }
  }, [selectedItem, lastSelectedItem, actions]);

  useEffect(() => {
    if (
      selectedItem &&
      selectedAiFlowRun &&
      aiFlowRunsOutputs &&
      aiFlowRunsOutputs[selectedAiFlowRun.id as number]
    ) {
      const tmpOutput = aiFlowRunsOutputs[selectedAiFlowRun.id as number].find(
        (item) => item.aiFlowItemId === selectedItem.id
      );
      if (tmpOutput && tmpOutput.output?.results) {
        setOutputs(tmpOutput.output?.results);
        setRunOutput(tmpOutput.output);
        setRunOutputId(tmpOutput.id);
        setFile(tmpOutput.output?.file ?? false);
        setStatus(tmpOutput?.status ?? '');
      }
    }
  }, [selectedItem, selectedAiFlowRun, aiFlowRunsOutputs]);

  return (
    <>
      {selectedItem &&
        selectedAction &&
        selectedAiFlowRun &&
        aiFlowRunsOutputs &&
        aiFlowRunsOutputs[selectedAiFlowRun.id as number] && (
          <>
            {outputs.length > 1 &&
              selectedAction.header !== 'Input' &&
              selectedAction.header !== 'Export' &&
              selectedAction.header !== 'Formatter' && (
                <>
                  <Stack justifyContent="space-between" direction="row" spacing={2}>
                    <div> </div>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 2,
                        marginBottom: 1,
                      }}
                    >
                      <Pagination
                        onChange={(event, page) => {
                          setOutputIndex(page - 1);
                        }}
                        count={outputs.length}
                      />
                    </Box>
                    {!fullScreen && (
                      <Box
                        sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                      >
                        <Tooltip title="Full screen">
                          <IconButton onClick={openFullScreen.onTrue}>
                            <Iconify icon="material-symbols:fullscreen" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                    {fullScreen && <div> </div>}
                  </Stack>
                </>
              )}
            {status !== 'Failed' && runOutput?.file 
              ? (
              <OutputCard
              multipleOutputs = {outputs.length > 1}
                outputId={runOutputId}
                outputInfo={
                  runOutput?.outputsInfo
                    ? (runOutput?.outputsInfo[outputIndex] as RunOutputInfo)
                    : undefined
                }
                status={status}
                files={outputs}
                header={selectedAction.header as string}
              />
            ) : (
              <OutputCard
              multipleOutputs = {outputs.length > 1}
                outputId={runOutputId}
                outputInfo={
                  runOutput?.outputsInfo
                    ? (runOutput?.outputsInfo[outputIndex] as RunOutputInfo)
                    : undefined
                }
                status={status}
                content={outputs[outputIndex] ?? ''}
                header={selectedAction.header as string}
              />
            )}
            {/* {selectedAction.actionType === 'GenerateFullEmail' && (
              <EmailContentPreviewCard
                generatedEmail={getFullEmailFormattedFromGenerated(output)}
              />
            )}
            {selectedAction.actionType === 'GenerateSubjectLine' && (
              <EmailContentPreviewCard
                generatedEmail={getFormattedEmailAdditionals(
                  aiFlowRunsOutputs[selectedAiFlowRun.id as number][
                    (selectedItem.sequence as number) - 1
                  ].output?.output as string,
                  selectedAction.actionType,
                  null
                )}
                selectedheader={selectedAction.actionType}
              />
            )}
            {selectedAction.actionType === 'GenerateIdeas' && (
              <ListCarousel
                list={getIdeasFormattedFromGenerated(
                  aiFlowRunsOutputs[selectedAiFlowRun.id as number][
                    (selectedItem.sequence as number) - 1
                  ]?.output?.output as string
                )}
              />
            )}
            {(selectedAction.actionType === 'Rephrase' ||
              selectedAction.actionType === 'ImportContent') && (
              <ContentCard
                content={
                  aiFlowRunsOutputs[selectedAiFlowRun.id as number][
                    (selectedItem.sequence as number) - 1
                  ].output?.output as string
                }
              />
            )} */}
          </>
        )}
      <FullPageDialog
        title="Outputs"
        zIndex={2500}
        children={<AiFlowItemOutputView fullScreen />}
        open={openFullScreen.value}
        onClose={() => {
          openFullScreen.onFalse();
        }}
      />
    </>
  );
}
