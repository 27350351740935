import { Grid, Card, Divider } from '@mui/material';
import { Container, Stack } from '@mui/system';
import { useParams } from 'react-router';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useBoolean } from 'src/hooks/use-boolean';
import AiFlowSheet from './AiFlowSheet';
import FullPageDialog from 'src/common/modals/FullPageDialog';
import { useDispatch, useSelector } from 'src/redux/store';
import AiFlowHeader from './AiFlowHeader';
import { setFullscreen, setRunMode } from 'src/redux/slices/aiflows';
import { useSettingsContext } from 'src/components/settings';
import { useEffect } from 'react';
import WorkflowDesigner from './workflow-designer/WorkflowDesigner';

interface Props {
  dialog?: boolean;
  edit?: boolean;
}
export default function AiFlowPage({ dialog, edit }: Props) {
  useParams();
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const fullScreen = useSelector((state) => state.aiFlows.fullscreen);
  const dispatch = useDispatch();
  // const [aiFlowItems, setAiFlowItems] = useState<AiFlowItemLogic[]>([]);

  // const onClickDeleteItem = (itemsequence: number) => {
  //   let newAiFlowItems = aiFlowItems.filter((item) => item.sequence !== itemsequence);
  //   newAiFlowItems = newAiFlowItems.map((item, index) => {
  //     item.sequence = index + 1;
  //     return item;
  //   });
  //   setAiFlowItems(newAiFlowItems);
  // };

  // const onClickAddAiFlowItem = (aiFlowItem: AiFlowItemLogic, addAfter?: number) => {
  //   // i want to insert it in the right position, so addlast will insert it in the end, addAfter will insert it at x index
  //   const aiFlowItemsLogic = aiFlowItems as AiFlowItemLogic[];
  //   let newAiFlowItems = [...aiFlowItemsLogic];
  //   if (addAfter) {
  //     newAiFlowItems.splice(addAfter, 0, aiFlowItem);
  //   } else {
  //     newAiFlowItems.push(aiFlowItem);
  //   }
  //   // set sequence ascending order
  //   newAiFlowItems = newAiFlowItems.map((item, index) => {
  //     item.sequence = index + 1;
  //     return item;
  //   });
  // };

  const openSelectRunDialog = useBoolean();
  const actionDialogOpen = useBoolean();
  const selectedFolder = useSelector((state) => state.folders.selectedFolder);
  const aiFlowSheetFullscreen = useBoolean(false);
  const handleSelectRun = (task: string) => {
    if (task === 'Action') {
      actionDialogOpen.onTrue();
    }
  };

  useEffect(() => {
    if (runsMode) {
      dispatch(setRunMode(false));
    }
  }, [dispatch, runsMode]);

  const { themeMode } = useSettingsContext();

  return (
    <>
      <Container maxWidth={false} sx={{ paddingLeft: '0px !important' }}>
        {!dialog && (
          <CustomBreadcrumbs
            heading={`${edit ? 'Edit' : 'New'} Flow`}
            links={
              selectedFolder
                ? [
                    { name: 'AI Flows', href: '/ai-workflows' },
                    {
                      name: selectedFolder?.name,
                      href: `/ai-workflows?folder=${selectedFolder?.name}`,
                    },
                    { name: `${edit ? 'Edit' : 'New'}` },
                  ]
                : [{ name: 'AI Flows', href: '/ai-workflows' }, { name: `${edit ? 'Edit' : 'New'}` }]
            }
          />
        )} 
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card
              sx={{
                p: 2,
                maxHeight: 850,
                background: themeMode === 'dark' ? '#212B36' : 'rgb(252,252,252)',
              }}
            >
              <Stack spacing={2}>
                <AiFlowHeader />
                <Divider sx={{ borderStyle: 'dashed', width: '100%' }} />
                <WorkflowDesigner />
                {/* <AiFlowSheet /> */}
              </Stack>
            </Card>
          </Grid>
        </Grid>
        {/* <ActionsDialog open={actionDialogOpen.value} onClose={actionDialogOpen.onFalse} /> */}
        {/* <AiFlowSelectRunDialog
          open={openSelectRunDialog.value}
          onClose={openSelectRunDialog.onFalse}
          onSelectRun={handleSelectRun}
        /> */}
        {!dialog && (
          <FullPageDialog
            title={`${edit ? 'Edit' : 'New'} Flow`}
            children={<AiFlowPage dialog />}
            open={fullScreen}
            onClose={() => {
              dispatch(setFullscreen(false));
            }}
          />
        )}
      </Container>
    </>
  );
}
