import { Dialog, DialogTitle, IconButton, DialogContent, Paper, Divider } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import _mock from 'src/_mock';
import Iconify from 'src/components/iconify';
import PromptEditor from 'src/components/prompt-editor/PromptEditor';
import { js as beautifyJs } from 'js-beautify';
import PdfViewer from './PdfViewer';
import OfficeFileViewer from './OfficeFileViewer';

export interface Props {
  onClose: () => void;
  open: boolean;
  fileUrl?: any;
  fileContent?: string;
  fileType?: string;
}

export default function FileContentViewerDialog({
  onClose,
  open,
  fileUrl,
  fileContent,
  fileType,
}: Props) {
  const [content, setContent] = useState<string>('');

  const isImageFileType = (type: string) =>
    // file type an be png, jpeg, jpg, gif, svg
    ['png', 'jpeg', 'jpg', 'gif', 'svg', 'webp', 'bmp', 'ico', 'tiff', 'tif'].includes(type);

  const isjsonFileType = (type: string) => ['json'].includes(type);

  const isAudioFileType = (type: string) => ['mp3', 'wav', 'ogg', 'flac', 'aac'].includes(type);

  const isPdfType = (type: string) => ['pdf'].includes(type);

  const isOfficeType = (type: string) => ['doc', 'docx', 'xls', 'xlsx'].includes(type);

  console.log('fileType', fileType);

  useEffect(() => {
    console.log('fileType', fileType);
  }, [fileType]);

  useEffect(() => {
    console.log('fileContent', fileContent);
    setContent(fileContent as string);
  }, [fileContent]);

  return (
    <Dialog
      sx={{ zIndex: 4400, minHeight: 700 }}
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        {' '}
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <span>File Content Viewer</span>
          <IconButton onClick={onClose}>
            <Iconify icon="material-symbols:close" width={20} height={20} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ my: 2, minHeight: 600 }}>
        <Stack spacing={2} sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
          <Paper
            key=""
            variant="outlined"
            sx={{
              py: 1.5,
              mx: 1,
              display: 'flex',
              position: 'relative',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              flexGrow: 1,
              height: '100%',
              width: '90%',
            }}
          >
            <Divider sx={{ mb: 1 }} />
            {isAudioFileType(fileType as string) && (
              <>
                <audio controls>
                  <source src={fileUrl} type="audio/mpeg" />
                  <track kind="captions" />
                  Your browser does not support the audio element.
                </audio>
              </>
            )}
            {isOfficeType(fileType as string) && <OfficeFileViewer url={fileUrl as string} />}
            {isPdfType(fileType as string) && <PdfViewer pdfUrl={fileUrl as string} />}
            {isImageFileType(fileType as string) && <img src={fileUrl} alt="PlaeHolder" />}
            {isjsonFileType(fileType as string) && (
              <PromptEditor
                height={600}
                lineNumbers
                //   minimap
                language="json"
                // i want to beautify the json file content
                // HOW CAN I DO THAT?

                promptInput={beautifyJs(content, {
                  indent_size: 2,
                  space_in_empty_paren: true,
                })}
                readonly
                setPromptInput={(prompt: string) => {}}
              />
            )}
          </Paper>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
