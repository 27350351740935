import { createSlice } from '@reduxjs/toolkit';
import { IAppStates } from 'src/@types/appStates';
// @types

// ----------------------------------------------------------------------

const initialState: IAppStates = {
    agentSetupMode: false,
     runsMode: false
};

const slice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    // START LOADING
    setAgentSetupMode(state,action){
        state.agentSetupMode = action.payload
    },
    setRunsMode(state,action){
        state.runsMode = action.payload
    }
  },
});

// Reducer
export default slice.reducer;

export const {
    setAgentSetupMode,
    setRunsMode

} = slice.actions;


