import { IconButton, useTheme } from "@mui/material";
import { Upload } from "@mui/icons-material";
import { useRef } from "react";
import { useSnackbar } from "src/components/snackbar";

interface FileUploadProps {
  onFileSelect: (files: File[]) => void;
  disabled?: boolean;
  darkMode?: boolean;
}

export default function FileUpload({ onFileSelect, disabled, darkMode }: FileUploadProps) {
  const theme = useTheme();
  const fileInputRef = useRef<HTMLInputElement>(null);
    const { enqueueSnackbar } = useSnackbar();
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    const invalidFiles = files.filter(file => {
      if (file.size > 5 * 1024 * 1024) {
        enqueueSnackbar("File too large", { variant: "error" });
        // toast({
        //   title: "File too large",
        //   description: `${file.name} is larger than 5MB`,
        //   variant: "destructive",
        // });
        return true;
      }

      if (!file.type.startsWith('image/')) {
        enqueueSnackbar("Invalid file type", { variant: "error" });
        // toast({
        //   title: "Invalid file type",
        //   description: `${file.name} is not an image file (PNG, JPG, GIF, etc)`,
        //   variant: "destructive",
        // });
        return true;
      }
      return false;
    });

    const validFiles = files.filter(file => !invalidFiles.includes(file));
    if (validFiles.length > 0) {
      onFileSelect(validFiles);
    }

    // Reset the input
    if (event.target) {
      event.target.value = '';
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        accept="image/*"
        multiple
      />
      <IconButton
        onClick={() => fileInputRef.current?.click()}
        disabled={disabled}
        sx={{
          ml: 1,
          mb: 1,
          color: darkMode? 'white' : theme.palette.text.secondary,
          "&:hover": {
            color: theme.palette.text.primary
          }
        }}
      >
        <Upload />
      </IconButton>
    </>
  );
}