import { Share2, Facebook, X, Mail, Link2, Copy, Linkedin } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';
import {
  Popover as MUIPopover,
  Box,
  Typography,
  Grid,
  IconButton,
  TextField,
  Divider,
  Popover,
  Button,
} from '@mui/material';
import Iconify from 'src/components/iconify';

interface Params{
    darkMode: boolean;
}
export function ShareButton({darkMode}: Params) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const currentUrl = window.location.href;
  const iframeCode = `<iframe src="${currentUrl}" width="100%" height="600" frameborder="0"></iframe>`;
 
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const copyToClipboard = (text: string, message: string) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success(message);
      handleClose();
    });
  };

  const shareViaGmail = () => {
    const subject = 'Check this out';
    const body = currentUrl;
    window.open(
      `https://mail.google.com/mail/?view=cm&fs=1&su=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`,
      '_blank'
    );
    handleClose();
  };

  const shareViaLinkedIn = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}`,
      '_blank'
    );
    handleClose();
  };

  const shareViaFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`,
      '_blank'
    );
    handleClose();
  };

  const shareViaTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`, '_blank');
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <Iconify sx = {{
            color: darkMode ? 'white' : 'black'
        }} icon="feather:share-2" />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPopover-paper': {
            width: 320,
            p: 3,
            borderRadius: '8px',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
          },
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography variant="subtitle1" fontWeight={500} mb={2}>
            Share
          </Typography>

          <Grid container spacing={2} mb={2}>
            <Grid item xs={2.4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 0.5,
                }}
              >
                <IconButton
                  onClick={() => copyToClipboard(iframeCode, 'Embed code copied!')}
                  sx={{
                    bgcolor: 'grey.100',
                    '&:hover': { bgcolor: 'grey.200' },
                    p: 1,
                  }}
                >
                  <Link2 className="h-5 w-5" />
                </IconButton>
                <Typography variant="caption">Embed</Typography>
              </Box>
            </Grid>

            <Grid item xs={2.4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 0.5,
                }}
              >
                <IconButton
                  onClick={shareViaLinkedIn}
                  sx={{
                    bgcolor: '#E8F4FF',
                    '&:hover': { bgcolor: '#D1E9FF' },
                    p: 1,
                  }}
                >
                  <Linkedin className="h-5 w-5 text-[#0A66C2]" />
                </IconButton>
                <Typography variant="caption">LinkedIn</Typography>
              </Box>
            </Grid>

            <Grid item xs={2.4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 0.5,
                }}
              >
                <IconButton
                  onClick={shareViaFacebook}
                  sx={{
                    bgcolor: '#E8F4FF',
                    '&:hover': { bgcolor: '#D1E9FF' },
                    p: 1,
                  }}
                >
                  <Facebook className="h-5 w-5 text-blue-600" />
                </IconButton>
                <Typography variant="caption">Facebook</Typography>
              </Box>
            </Grid>

            <Grid item xs={2.4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 0.5,
                }}
              >
                <IconButton
                  onClick={shareViaTwitter}
                  sx={{
                    bgcolor: 'rgba(0, 0, 0, 0.1)',
                    '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.15)' },
                    p: 1,
                  }}
                >
                  <X className="h-5 w-5" />
                </IconButton>
                <Typography variant="caption">X</Typography>
              </Box>
            </Grid>

            <Grid item xs={2.4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 0.5,
                }}
              >
                <IconButton
                  onClick={shareViaGmail}
                  sx={{
                    bgcolor: '#FFEFEE',
                    '&:hover': { bgcolor: '#FFE4E3' },
                    p: 1,
                  }}
                >
                  <Mail className="h-5 w-5 text-red-500" />
                </IconButton>
                <Typography variant="caption">Gmail</Typography>
              </Box>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          <Box sx={{ display: 'flex', gap: 1 }}>
            <TextField
              fullWidth
              size="small"
              value={currentUrl}
              InputProps={{
                readOnly: true,
                sx: { fontSize: '0.875rem' },
              }}
            />
            <Button
              onClick={() => copyToClipboard(currentUrl, 'Link copied!')}
              size="small"
              sx = {{
                backgroundColor: darkMode ? 'black' : 'white',
                color: darkMode ? 'white' : 'black',
              }}
              variant="contained"
            >
              Copy
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
