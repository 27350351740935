import { createSlice, Dispatch } from '@reduxjs/toolkit';
import _ from 'lodash';
import { IKnowledgeBaseState } from 'src/@types/knowledge-base';
// @types
import { KnowledgeBaseApi, KnowledgeBaseFileLogic, KnowledgeBaseLogic } from 'src/api';

// ----------------------------------------------------------------------

const initialState: IKnowledgeBaseState = {
  knowledgeBases: [],
  knowledgeBaseFiles: [],
  addingToVectorStore: false,
  selectedKnowledgeBase: null,
  selectedKnowledgeBaseFile: null,
  error: null,
  knowledgeBaseFilesLoading: false,
  knowledgeBasesLoading: false,
  knowledgeBasesLoaded: false,
  knowledgeBaseFilesLoaded: false,
};

const slice = createSlice({
  name: 'expressions',
  initialState,
  reducers: {
    fethKnowledgeBaseSuccess(state, action) {
      state.knowledgeBasesLoading = false;
      state.knowledgeBasesLoaded = true;
      state.knowledgeBases = action.payload;
    },

    updateKnowledgeBasesSuccess(state, action) {
      state.knowledgeBasesLoading = false;
      state.knowledgeBasesLoaded = true;
      state.knowledgeBases = state.knowledgeBases.map((kb) => {
        if (kb.id === action.payload.id) {
          return action.payload;
        }
        return kb;
      });
    },

    deleteKnowledgeBaseSuccess(state, action) {
      state.knowledgeBasesLoading = false;
      state.knowledgeBasesLoaded = true;
      state.knowledgeBases = state.knowledgeBases.filter((kb) => kb.id !== action.payload);
    },
    setAddingToVectorStore(state, action) {
      state.addingToVectorStore = action.payload;
    },
    setSelectedKnowledgeBase(state, action) {
      state.selectedKnowledgeBase = action.payload;
    },
    setSelectedKnowledgeBaseFile(state, action) {
      state.selectedKnowledgeBaseFile = action.payload;
    },
    createKnowledgeBaseSuccess(state, action) {
      state.knowledgeBasesLoading = false;
      state.knowledgeBasesLoaded = true;
      state.knowledgeBases = [...state.knowledgeBases, action.payload];
    },
    ///
    fethKnowledgeBaseFilesSuccess(state, action) {
      state.knowledgeBaseFilesLoading = false;
      state.knowledgeBaseFilesLoaded = true;
      state.knowledgeBaseFiles = action.payload;
    },

    updateKnowledgeBaseFileSuccess(state, action) {
      state.knowledgeBaseFilesLoading = false;
      state.knowledgeBaseFilesLoaded = true;
      state.knowledgeBaseFiles = state.knowledgeBaseFiles.map((kbf) => {
        if (kbf.id === action.payload.id) {
          return action.payload;
        }
        return kbf;
      });
    },

    deleteKnowledgeBaseFileSuccess(state, action) {
      state.knowledgeBaseFilesLoading = false;
      state.knowledgeBaseFilesLoaded = true;
      state.knowledgeBaseFiles = state.knowledgeBaseFiles.filter((kb) => kb.id !== action.payload);
    },

    deleteKnowledgeBaseFilesSuccess(state, action) {
      state.knowledgeBaseFilesLoading = false;
      state.knowledgeBaseFilesLoaded = true;
      state.knowledgeBaseFiles = state.knowledgeBaseFiles.filter(
        (kb) => !action.payload.includes(kb.id)
      );
    },

    createKnowledgeBaseFileSuccess(state, action) {
      state.knowledgeBaseFilesLoading = false;
      state.knowledgeBaseFilesLoaded = true;
      console.log('action.payload', _.cloneDeep(action.payload));
      console.log('state.knowledgeBaseFiles', _.cloneDeep(state.knowledgeBaseFiles));
      state.knowledgeBaseFiles = [...state.knowledgeBaseFiles, ...action.payload];
      console.log('state.knowledgeBaseFiles', _.cloneDeep(state.knowledgeBaseFiles));
      state.selectedKnowledgeBaseFile = null;
    },
    createKnowledgeBaseFilesSuccess(state, action) {
      state.knowledgeBaseFilesLoading = false;
      state.knowledgeBaseFilesLoaded = true;
      state.knowledgeBaseFiles = [...state.knowledgeBaseFiles, ...action.payload];
    },

    // HAS ERROR
    hasError(state, action) {
      state.knowledgeBasesLoading = false;
      state.knowledgeBasesLoaded = false;
      state.knowledgeBaseFilesLoading = false;
      state.addingToVectorStore = false;
      state.error = action.payload;
    },
  },
});

// Actions
export const { setSelectedKnowledgeBaseFile, setSelectedKnowledgeBase, hasError } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function fetchKnowledgeBases() {
  return async (dispatch: Dispatch) => {
    try {
      const knowledgeBaseApi = new KnowledgeBaseApi();
      const response = await knowledgeBaseApi.apiKnowledgeBaseGet();
      dispatch(slice.actions.fethKnowledgeBaseSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// finish crud
export function createKnowledgeBase(knowledgeBase: KnowledgeBaseLogic, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const knowledgeBaseApi = new KnowledgeBaseApi();
      const response = await knowledgeBaseApi.apiKnowledgeBasePost(knowledgeBase);
      enqueueSnackbar(`${knowledgeBase.name} was created successfully`, {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
      dispatch(slice.actions.createKnowledgeBaseSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error creating Files Repo', { variant: 'error' });
    }
  };
}

export function updateKnowledgeBase(knowledgeBase: KnowledgeBaseLogic, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const knowledgeBaseApi = new KnowledgeBaseApi();
      const response = await knowledgeBaseApi.apiKnowledgeBasePut(knowledgeBase);
      dispatch(slice.actions.updateKnowledgeBasesSuccess(knowledgeBase));
      enqueueSnackbar('Files Repo updated successfully', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error updating Files Repo', { variant: 'error' });
    }
  };
}

export function deleteKnowledgeBase(knowledgeBaseId: number, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const knowledgeBaseApi = new KnowledgeBaseApi();
      await knowledgeBaseApi.apiKnowledgeBaseKnowledgeBaseIdDelete(knowledgeBaseId);
      dispatch(slice.actions.deleteKnowledgeBaseSuccess(knowledgeBaseId));
      enqueueSnackbar('Files Repo deleted successfully', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error deleting Files Repo', { variant: 'error' });
    }
  };
}

export function fetchKnowledgeBaseFiles(knowledgeBaseId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const knowledgeBaseApi = new KnowledgeBaseApi();
      const response = await knowledgeBaseApi.apiKnowledgeBaseKnowledgeBaseKnowledgeBaseIdFilesGet(
        knowledgeBaseId
      );
      dispatch(slice.actions.fethKnowledgeBaseFilesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// finish crud
export function createKnowledgeBaseFile(
  knowledgeBaseFile: KnowledgeBaseFileLogic,
  enqueueSnackbar: any
) {
  return async (dispatch: Dispatch) => {
    try {
      const knowledgeBaseApi = new KnowledgeBaseApi();
      const response = await knowledgeBaseApi.apiKnowledgeBaseFilePost(knowledgeBaseFile);
      enqueueSnackbar('File added successfully', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
      dispatch(slice.actions.createKnowledgeBaseFileSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error adding file', { variant: 'error' });
    }
  };
}

export function updateKnowledgeBaseFile(
  knowledgeBaseFile: KnowledgeBaseFileLogic,
  enqueueSnackbar: any
) {
  return async (dispatch: Dispatch) => {
    try {
      const knowledgeBaseApi = new KnowledgeBaseApi();
      const response = await knowledgeBaseApi.apiKnowledgeBaseKnowledgeBasePut(knowledgeBaseFile);
      dispatch(slice.actions.updateKnowledgeBaseFileSuccess(knowledgeBaseFile));
      enqueueSnackbar('File updated successfully', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error updating File', { variant: 'error' });
    }
  };
}

export function deleteKnowledgeBaseFile(knowledgeBaseFileId: number, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const knowledgeBaseApi = new KnowledgeBaseApi();
      await knowledgeBaseApi.apiKnowledgeBaseKnowledgeBaseKnowledgeBaseFileIdDelete(
        knowledgeBaseFileId
      );
      dispatch(slice.actions.deleteKnowledgeBaseFileSuccess(knowledgeBaseFileId));
      enqueueSnackbar('File deleted successfully', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error deleting File', { variant: 'error' });
    }
  };
}

export function deleteKnowledgeBaseFiles(knowledgeBaseFileIds: number[], enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const knowledgeBaseApi = new KnowledgeBaseApi();
      await knowledgeBaseApi.apiKnowledgeBaseKnowledgeBaseFilesDelete(knowledgeBaseFileIds);
      dispatch(slice.actions.deleteKnowledgeBaseFilesSuccess(knowledgeBaseFileIds));
      enqueueSnackbar('Files deleted successfully', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error deleting File', { variant: 'error' });
    }
  };
}

export function AddKnowledgeBaseFiles(
  kbFiles: KnowledgeBaseFileLogic[],
  enqueueSnackbar: any,
  onClose: any
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setAddingToVectorStore(true));
      const knowledgeBaseApi = new KnowledgeBaseApi();
      const response = await knowledgeBaseApi.apiKnowledgeBaseFilesPost(kbFiles);
      enqueueSnackbar('Files added successfully', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
      dispatch(slice.actions.createKnowledgeBaseFileSuccess(response.data));
      dispatch(slice.actions.setAddingToVectorStore(false));
      onClose();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error adding files', { variant: 'error' });
    }
  };
}
