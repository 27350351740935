import { createSlice, Dispatch } from '@reduxjs/toolkit';
import _ from 'lodash';
import { AiFlowItem } from 'src/@types/aiflow';
import { AiFlowRunsState } from 'src/@types/aiFlow-runs';
// @types
import { AiFlowLogic, AiFlowRunLogic, AiFlowRunsApi, AiFlowRunStatus } from 'src/api';
import mapAiFlowItemData from 'src/utils/aiFlowsUtils';

// ----------------------------------------------------------------------

const initialState: AiFlowRunsState = {
  runs: [],
  aiFlowRunsOutputs: {},
  aiFlowRunsOutputsByHeader: {},
  selectedOutputComments: [],
  aiFlowRunsOutputsByHeaderCount: {},
  aiFlowRunItems: {},
  selectedHeader: undefined,
  selectedAiFlowRun: undefined,
  error: null,
  runningAiFlow: false,
  loadingRuns: false,
  loadingRunOutputs: false,
  loadingRunOutputsByHeader: false,
  loadedRunOutputsByHeader: false,
  loadedRuns: false,
};

const slice = createSlice({
  name: 'aiFlow-runs',
  initialState,
  reducers: {
    // START LOADING
    startFetchingRuns(state) {
      state.loadingRuns = true;
    },
    startFetchingRunOutputs(state) {
      state.loadingRunOutputs = true;
    },
    fetchRunSuccess(state, action) {
      state.loadingRuns = false;
      state.loadedRuns = true;
      state.runs = action.payload;
    },
    fetchSelectedOutputCommentsSuccess(state, action) {
      state.selectedOutputComments = action.payload;
    },
    updateOutputCommentSuccess(state, action) {
      const updatedComment = action.payload;
      const commentIndex = state.selectedOutputComments.findIndex(
        (comment) => comment.id === updatedComment.id
      );
      state.selectedOutputComments[commentIndex] = updatedComment;
    },
    setSelectedHeader(state, action) {
      state.selectedHeader = action.payload;
    },
    addNewOutputComment(state, action) {
      // add at the beginning
      const outputComment = action.payload;
      state.selectedOutputComments.unshift(outputComment as never);
      if (state.selectedHeader) {
        const outputTmp = state.aiFlowRunsOutputsByHeader[state.selectedHeader as string].find(
          (output: any) => output.id === outputComment.aiFlowRunOutputId
        );
        if (outputTmp) {
          if (!outputTmp.comments) {
            outputTmp.comments = [];
          }
          outputTmp.comments.unshift(outputComment);
        }
      }
    },
    fetchRunOutputsSuccess(state, action) {
      //   state.loadingRunOutputs = false;
      state.aiFlowRunsOutputs[action.payload.aiFlowRunId] = action.payload.outputs;
    },
    addRunOutput(state, action) {
      const runId = action.payload.aiFlowRunId;
      const runOutput = action.payload;

      if (!state.aiFlowRunsOutputs[runId]) {
        state.aiFlowRunsOutputs[runId] = [];
      }
      if (state.aiFlowRunsOutputs[runId].find((output) => output.id === runOutput.id)) {
        return;
      }
      state.aiFlowRunsOutputs[runId].push(runOutput);
    },
    fetchRunOutputsByHeaderSuccess(state, action) {
      state.aiFlowRunsOutputsByHeader[action.payload.header] = action.payload.data;
      state.aiFlowRunsOutputsByHeaderCount[action.payload.header] = action.payload.data.length;
      state.selectedHeader = action.payload.header;
      // i want to add now the header outsputs to aiFlowRunsOutputs dictionary. the key is run id and value is outputs
      action.payload.data.forEach((runOutput: any) => {
        const runId = runOutput.runId;
        if (!state.aiFlowRunsOutputs[runId]) {
          state.aiFlowRunsOutputs[runId] = [];
        }
        if (!state.aiFlowRunsOutputs[runId].find((output) => output.id === runOutput.id)) {
          state.aiFlowRunsOutputs[runId].push(runOutput);
        }
      });
      state.loadingRunOutputs = false;
      state.loadingRunOutputsByHeader = false;
      state.loadedRunOutputsByHeader = true;
    },
    setLoadingRunOutputs(state, action) {
      state.loadingRunOutputs = action.payload;
      state.loadingRunOutputsByHeader = action.payload;
    },
    fetchRunOutputsByHeaderCountSuccess(state, action) {
      //  state.loadingRunOutputs = false;
      state.aiFlowRunsOutputsByHeaderCount = action.payload;
      state.loadedRunOutputsByHeader = true;
    },
    // updateRunOutput(state, action) {
    //   const existRunOutputIndex = state.aiFlowRunsOutputs[action.payload.aiFlowRunId].findIndex(
    //     (runOutput: any) => runOutput.id === action.payload.id
    //   );

    //   if (existRunOutputIndex < 0) {
    //     state.aiFlowRunsOutputs[action.payload.aiFlowRunId].push(action.payload);
    //     return;
    //   }
    //   state.aiFlowRunsOutputs[action.payload.aiFlowRunId][existRunOutputIndex] = action.payload;
    // },
    // updateRun(state, action) {
    //   const existRunIndex = state.runs.findIndex((run: any) => run.id === action.payload.id);

    //   if (existRunIndex < 0) return;
    //   const updatedRun = action.payload as AiFlowRunLogic;
    //   state.runs[existRunIndex] = updatedRun;
    //   if (state.selectedAiFlowRun?.id === action.payload.id) {
    //     state.selectedAiFlowRun = updatedRun;
    //   }
    // },
    fetchRunsItemsSuccess(state, action) {
      state.loadingRuns = false;
      state.loadedRuns = true;
      const aiFlowRunItems = action.payload.aiFlowRunsItems;
      Object.keys(aiFlowRunItems).forEach((runId) => {
        let aiFlowItems = aiFlowRunItems[+runId]; // +runId converts string to number
        aiFlowItems = aiFlowItems.map((item: any) => {
          item = mapAiFlowItemData(
            item,
            aiFlowItems,
            action.payload.actions,
            action.payload.groups
          );
          // Process each AiFlowItemLogic
          return item;
        });
        aiFlowRunItems[+runId] = aiFlowItems;
      });
      state.aiFlowRunItems = aiFlowRunItems;
    },
    selectAiFlowRun(state, action) {
      state.selectedAiFlowRun = state.runs.find((run: any) => run.id === action.payload);
    },
    updateRun(state, action) {
      const existRunIndex = state.runs.findIndex((run: any) => run.id === action.payload.id);

      if (existRunIndex < 0) return;
      const updatedRun = action.payload as AiFlowRunLogic;
      state.runs[existRunIndex] = updatedRun;
      if (state.selectedAiFlowRun?.id === action.payload.id) {
        state.selectedAiFlowRun = updatedRun;
      }
    },
    updateRunOutput(state, action) {
      const existRunOutputIndex = state.aiFlowRunsOutputs[action.payload.aiFlowRunId].findIndex(
        (runOutput: any) => runOutput.id === action.payload.id
      );

      if (existRunOutputIndex < 0) {
        state.aiFlowRunsOutputs[action.payload.aiFlowRunId].push(action.payload);
        return;
      }
      state.aiFlowRunsOutputs[action.payload.aiFlowRunId][existRunOutputIndex] = action.payload;
    },
    addAiFlowRun(state, action) {
      const aiFlowRun = action.payload as AiFlowRunLogic;
      aiFlowRun.runNumber = state.runs.length + 1;
      state.runs.push(aiFlowRun);
    },
    addAiFlowRunItems(state, action) {
      let items = _.cloneDeep(action.payload.items) as AiFlowItem[];
      if (items) {
        items = items.map((item: any) => {
          item = mapAiFlowItemData(item, items, action.payload.actions, action.payload.groups);
          // Process each AiFlowItemLogic
          return item;
        });
      }
      state.aiFlowRunItems[action.payload.aiFlowRunId] = items;
    },
    updateRunStatus(state, action) {
      state.loadingRuns = false;
      state.loadedRuns = true;
      state.runs = state.runs.map((run: any) => {
        if (run.id === action.payload.runId) {
          run.status = action.payload.newStatus;
          if (run.status === 'Completed') {
            run.sequence = null;
          }
        }
        return run;
      });
    },
    cancelRun(state, action) {
      state.loadingRuns = false;
      state.loadedRuns = true;
      state.runs = state.runs.map((run: any) => {
        if (run.id === action.payload.runId) {
          run.status = 'Canceled';
          run.sequence = null;
          if (state.selectedAiFlowRun?.id === run.id) {
            state.selectedAiFlowRun = run;
          }
          const runOutputs = state.aiFlowRunsOutputs[run.id];
          if (runOutputs) {
            runOutputs.forEach((runOutput) => {
              if (runOutput.status === 'InProcess' || runOutput.status === 'Pending') {
                runOutput.status = 'Canceled';
              }
            });
          }
        }
        return run;
      });
    },
    setRunningAiFlow(state, action) {
      state.runningAiFlow = action.payload;
    },
    deleteOutputComment(state, action) {
      const commentId = action.payload.commentId;
      const outputId = action.payload.outputId;
      state.selectedOutputComments = state.selectedOutputComments.filter(
        (comment) => comment.id !== commentId
      );
      if (state.selectedHeader) {
        const tmpOutput = state.aiFlowRunsOutputsByHeader[state.selectedHeader].find(
          (output: any) => output.id === outputId
        );
        if (tmpOutput && tmpOutput.comments) {
          tmpOutput.comments = tmpOutput.comments.filter(
            (comment: any) => comment.id !== commentId
          );
        }
      }
    },
    // HAS ERROR
    hasError(state, action) {
      state.loadingRuns = false;
      state.loadingRunOutputs = false;
      state.loadedRuns = false;
      state.error = action.payload;
    },
  },
});

export const {
  addAiFlowRun,
  addRunOutput,
  updateRunOutput,
  fetchSelectedOutputCommentsSuccess,
  fetchRunOutputsSuccess,
  selectAiFlowRun,
  updateRun,
  addAiFlowRunItems,
  setLoadingRunOutputs,
  updateRunStatus,
} = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function fetchAiFlowsRuns() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startFetchingRuns());
    try {
      const aiFlowRunApi = new AiFlowRunsApi();
      const aiFlowsRunsResponse = await aiFlowRunApi.apiAiFlowRunsAiFlowsRunsGet();
      dispatch(slice.actions.fetchRunSuccess(aiFlowsRunsResponse.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function fetchAiFlowRunItems(aiFlowRunId: number) {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const aiFlowRunApi = new AiFlowRunsApi();
      const aiFlowsRunsResponse = await aiFlowRunApi.apiAiFlowRunsAiFlowRunItemsGet(aiFlowRunId);
      const actions = getState().aiFlows.actions;
      const groups = getState().aiFlows.actionGroups;
      dispatch(
        slice.actions.addAiFlowRunItems({
          aiFlowRunId,
          items: aiFlowsRunsResponse.data,
          actions,
          groups,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function fetchAiFlowsRunOutputs(aiFlowRunId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startFetchingRunOutputs());
    try {
      const aiFlowRunApi = new AiFlowRunsApi();
      const aiFlowsRunOutputsResponse = await aiFlowRunApi.apiAiFlowRunsAiFlowRunsOutputsGet(
        aiFlowRunId
      );
      dispatch(
        slice.actions.fetchRunOutputsSuccess({
          aiFlowRunId,
          outputs: aiFlowsRunOutputsResponse.data,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function fetchAllAiFlowRunsOutputsByHeader() {
//   return async (dispatch: Dispatch, getState: any) => {
//     try {
//       const aiFlowRunApi = new AiFlowRunsApi();
//       const aiFlowsRunsResponse = await aiFlowRunApi.apiAiFlowRunsAiFlowAllRunsOutputsByHeaderGet();
//       dispatch(slice.actions.fetchRunOutputsByHeaderSuccess(aiFlowsRunsResponse.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function fetchAiFlowRunsOutputsByHeader(header: string) {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch(setLoadingRunOutputs(true));
      const aiFlowRunApi = new AiFlowRunsApi();
      const aiFlowsRunsResponse = await aiFlowRunApi.apiAiFlowRunsAiFlowRunsOutputsByHeaderGet(
        header
      );
      dispatch(
        slice.actions.fetchRunOutputsByHeaderSuccess({
          data: aiFlowsRunsResponse.data,
          header,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchAiFlowRunsOutputsByHeaderCount() {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const aiFlowRunApi = new AiFlowRunsApi();
      const aiFlowsRunsResponse =
        await aiFlowRunApi.apiAiFlowRunsAiFlowRunsOutputsByHeaderCountGet();
      dispatch(slice.actions.fetchRunOutputsByHeaderCountSuccess(aiFlowsRunsResponse.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function cancelAiFlowRun(aiFlowRunId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const aiFlowRunApi = new AiFlowRunsApi();
      await aiFlowRunApi.apiAiFlowRunsCancelAiflowPut({
        aiFlowRunId,
      });
      dispatch(
        slice.actions.cancelRun({
          runId: aiFlowRunId,
          newStatus: 'Canceled',
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchAiFlowRunsItems() {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const aiFlowRunApi = new AiFlowRunsApi();
      const aiFlowsRunsResponse = await aiFlowRunApi.apiAiFlowRunsAiFlowRunsItemsGet();
      const actions = getState().aiFlows.actions;
      const groups = getState().aiFlows.actionGroups;
      dispatch(
        slice.actions.fetchRunsItemsSuccess({
          aiFlowRunsItems: aiFlowsRunsResponse.data.aiFlowRunsItems,
          actions,
          groups,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function changeRunStatus(runsId: number, newStatus: AiFlowRunStatus, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const aiFlowRunApi = new AiFlowRunsApi();
      const aiFlowsRunsResponse = await aiFlowRunApi.apiAiFlowRunsPut({
        aiFlowRunId: runsId,
        newStatus,
      });
      enqueueSnackbar('Runs status has changed.', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error changing task status.', { variant: 'error' });
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchOutputComments(outputId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const aiflowRunApi = new AiFlowRunsApi();
      const ocResponse = await aiflowRunApi.apiAiFlowRunsOutputCommentsGet(outputId);
      dispatch(slice.actions.fetchSelectedOutputCommentsSuccess(ocResponse.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addOutputComment(newCom: any) {
  return async (dispatch: Dispatch) => {
    try {
      const aiflowRunApi = new AiFlowRunsApi();
      const created = await aiflowRunApi.apiAiFlowRunsCreateOutputCommentPost(newCom);
      dispatch(slice.actions.addNewOutputComment(created.data));
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateOutputComment(updatedComment: any) {
  return async (dispatch: Dispatch) => {
    try {
      const aiflowRunApi = new AiFlowRunsApi();
      await aiflowRunApi.apiAiFlowRunsOutputCommentPut({
        outputComment: updatedComment,
      });
      dispatch(slice.actions.updateOutputCommentSuccess(updatedComment));
    } catch (error) {
      console.error(error);
    }
  };
}

export function deleteOutputComment(commentId: number, outputId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const aiflowRunApi = new AiFlowRunsApi();
      await aiflowRunApi.apiAiFlowRunsOutputCommentDelete(commentId);
      dispatch(
        slice.actions.deleteOutputComment({
          commentId,
          outputId,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
}

export function runAiFlow(
  aiFlow: AiFlowLogic,
  aiFlowVersionId: number,
  aiflowItems: AiFlowLogic[],
  enqueueSnackbar: any
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setRunningAiFlow(true));
      const wfRunsApi = new AiFlowRunsApi();
      const runAiFlowResponse = await wfRunsApi.apiAiFlowRunsRunAiflowPost({
        aiFlowId: aiFlow.id as number,
        aiFlowVersionId,
        items: aiflowItems,
      });
      dispatch(addAiFlowRun(runAiFlowResponse.data));
      dispatch(
        addAiFlowRunItems({
          aiFlowRunId: runAiFlowResponse.data.id as number,
          items: aiFlow.items?.filter((i) => i.aiFlowVersionId === aiFlowVersionId) as AiFlowItem[],
        })
      );
      enqueueSnackbar('New run has been added to the queue.', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`${error.response.data.Message}`, { variant: 'error' });
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setRunningAiFlow(false));
    }
  };
}