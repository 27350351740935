import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Scrollbar from 'src/components/scrollbar';
import Iconify from 'src/components/iconify';
import { useDispatch, useSelector } from 'src/redux/store';
import { useEffect, useState } from 'react';
import { StyledIcon } from 'src/components/nav-section/mini/styles';
import { ACTION_ICONS } from 'src/common/constants/icons.constants';
import { RHAutocomplete } from 'src/components/hook-form/RHFAutocomplete';
import { RHSelect } from 'src/components/hook-form';
import { addOnboarding } from 'src/redux/slices/user';
import { LoadingButton } from '@mui/lab';

export default function WelcomeDialog() {
  const user = useSelector((state) => state.user.user);
  const profile = useSelector((state) => state.user.profile);
  const onboarding = useSelector((state) => state.user.onboarding);
  const [open, setOpen] = useState(false);
  const [companySizes, setCompanySizes] = useState<string[]>([
    '1-49',
    '50-249',
    '250-999',
    '1000-9999',
    '10000+',
  ]);
  const [selectedCompanySize, setSelectedCompanySize] = useState<string>('1-49');
  const [selectedRole, setSelectedRole] = useState<string>('Marketing');
  const [initializing, setInitializing] = useState(true);
  const [otherRole, setOtherRole] = useState('');
  const loading = useSelector((state) => state.user.loading);
  const loaded = useSelector((state) => state.user.loaded);
  const [expanded, setExpanded] = useState<string | false>(false);
  const isInitialized = useSelector((state) => state.auth.initzialized);
  const [fields, setFields] = useState<string[]>([
    'Support',
    'Marketing',
    'Sales',
    'Product',
    'Finance',
    'HR',
    'Operations',
    'Engineering',
    'Data Science',
    'Design',
    'Research',
    'Business',
    'Other',
  ]);
  const [automationGoals, setAutomationGoals] = useState<string[]>([
    'Automate Routine Tasks',
    'Analyze large datasets',
    'Enhance Decision-Making',
    'Enhance user engagement',
    'Optimize Resource Allocation',
    'Other'
  ]);
  const [selectedAutomationGoals, setSelectedAutomationGoals] = useState<string[]>([
    'Automate Routine Tasks',
    'Enhance Decision-Making',
  ]);
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleClose = (event: any, reason: any) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      // return;
    }
    // onClose();
  };

  useEffect(() => {
    if (initializing) {
      setTimeout(() => {
        setInitializing(false);
      }, 3000);
    }
  }, [initializing]);

  const dispatch = useDispatch();

  return (
    <Dialog
      fullWidth
      open={!onboarding && isInitialized && !initializing && loaded}
      onClose={handleClose}
    >
      <DialogContent sx={{ typography: 'body2', overflow: 'hidden' }}>
        <Scrollbar sx={{ maxHeight: 815 }}>
          <Box sx={{ p: 3, width: '100%', overflow: 'hidden' }}>
            <Stack
              spacing={3}
              justifyContent="center"
              display="flex"
              alignSelf="center"
              alignItems="center"
              width="100%"
            >
              <Typography variant="h4">Welcome {profile?.fullName} 🥳</Typography>
              <Typography variant="h6">
                We&apos;re thrilled to have you joined our early access!
              </Typography>
              <Typography variant="h6">Follow these simple steps to get started:</Typography>
              {/* <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
                  <Stack direction="row" spacing={1.5}>
                  <StyledIcon>{ACTION_ICONS.actions}</StyledIcon>
                    <Typography variant="subtitle1">1. Try out different actions</Typography>
                  </Stack>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography>blabla</Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
                <Stack direction="row" spacing={1.5}>
                  <StyledIcon>{ACTION_ICONS.aiFlows}</StyledIcon>
                    <Typography variant="subtitle1">  2. Setup your custom automation aiFlows</Typography>
                  </Stack>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography>blabla</Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
                <Stack direction="row" spacing={1.5}>
                  <StyledIcon>{ACTION_ICONS.integrations}</StyledIcon>
                    <Typography variant="subtitle1">   3. Enrich your automations with integrations</Typography>
                  </Stack>
    
                </AccordionSummary>

                <AccordionDetails>
                  <Typography>blabla</Typography>
                </AccordionDetails>
              </Accordion> */}
              <RHSelect
                key="role"
                name="role"
                placeholder="Select your role"
                size="small"
                variant="standard"
                value={selectedRole}
                onChange={(e) => {
                  setSelectedRole(e.target.value);
                }}
                label="Select a field"
                zIndex={2500}
                InputLabelProps={{ shrink: true }}
                sx={{ px: 2 }}
              >
                {fields.map((role) => (
                  <MenuItem key={role} value={role}>
                    <span>{role}</span>
                  </MenuItem>
                ))}
              </RHSelect>
              {selectedRole === 'Other' && (
                <TextField
                  key="role"
                  name="role"
                  placeholder="Enter your role"
                  size="small"
                  variant="standard"
                  value={otherRole}
                  onChange={(e) => {
                    setOtherRole(e.target.value);
                  }}
                  label="Enter your role"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: '100%', px: 2 }}
                />
              )}
              <RHAutocomplete
                name="automationGoals"
                label="Goals to achieve with AI Agents"
                variant="standard"
                placeholder="+ Tags"
                zIndex={2500}
                size="small"
                sx={{ px: 2, minWidth: '100%', zIndex: 2500 }}
                onChange={(event, value) => {
                  setSelectedAutomationGoals(value);
                }}
                multiple
                value={selectedAutomationGoals ?? []}
                disableCloseOnSelect
                options={automationGoals.map((option) => option)}
                getOptionLabel={(option) => option}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
                renderTags={(selected, getTagProps) =>
                  selected.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={option}
                      label={option}
                      size="small"
                      color="info"
                      variant="soft"
                    />
                  ))
                }
              />
              <RHSelect
                key="companySize"
                name="companySize"
                placeholder="Company Size"
                size="small"
                variant="standard"
                value={selectedCompanySize}
                onChange={(e) => {
                  setSelectedCompanySize(e.target.value);
                }}
                label="Company Size"
                zIndex={2500}
                InputLabelProps={{ shrink: true }}
                sx={{ px: 2 }}
              >
                {companySizes.map((size) => (
                  <MenuItem key={size} value={size}>
                    <span>{size}</span>
                  </MenuItem>
                ))}
              </RHSelect>
              {/* <Paper
                elevation={3}
                style={{ borderRadius: '12px', marginBottom: 2, overflow: 'hidden' }}
              >
                <iframe
                  width="500"
                  height="315"
                  src="https://www.youtube.com/embed/hulyOOjXtFc?si=EsBBfj4qMRbBRmXo"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                />
              </Paper> */}

              <LoadingButton
                loading={loading}
                variant="outlined"
                size="large"
                sx={{
                  mt: 2,
                  maxWidth: { md: 160 },
                  maxHeight: { md: 40 },
                }}
                onClick={() => {
                  dispatch(
                    addOnboarding({
                      automationObjectives: selectedAutomationGoals.join(','),
                      companySize: selectedCompanySize,
                      role: selectedRole === 'Other' ? otherRole : selectedRole,
                    })
                  );
                  handleClose(null, null);
                  setOpen(false);
                }}
              >
                Let&apos;s start!
              </LoadingButton>
            </Stack>
          </Box>
        </Scrollbar>
      </DialogContent>
    </Dialog>
  );
}
