import {
  CircularProgress,
  Divider,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import ConfigurationButton from 'src/components/configurations/ConfigurationsButton';
import { useDispatch, useSelector } from 'src/redux/store';
import { ActionInfo, ConfigurationLogic, AiFlowItemLogic } from 'src/api';
import _ from 'lodash';
import AiFlowGenerateActionSetup from './action/generate/AiFlowGenerateActionSetup';
import { useSnackbar } from 'src/components/snackbar';
import { setSidebarPopoverOpen, updateSelectedAiFlowItem, updateTempSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import PromptEditor from 'src/components/prompt-editor/PromptEditor';
import FormProvider, { RHFTextField, RHSelect } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AiFlowInputActionSetup from './action/system/input/AiFlowInputActionSetup';
import AiFlowExportActionSetup from './action/export/AiFlowExportActionSetup';
import AiFlowSegmentActionSetup from './action/functional/AiFlowSegmentActionSetup';
import PreviousActionsChips from 'src/components/previous-actions/PreviousActionsChips';
import AiFlowExtractorActionSetup from './action/functional/AiFlowExtractorActionSetup';
import AiFlowTransformerActionSetup from './action/functional/AiFlowTransformerActionSetup';
import AiFlowScrapperActionSetup from './action/functional/AiFlowScrapperActionSetup';
import AiFlowLocatorActionSetup from './action/functional/AiFlowLocatorActionSetup';
import AiFlowValidatorActionSetup from './action/functional/AiFlowValidatorActionSetup';
import AiFlowCombineActionSetup from './action/functional/AiFlowCombineActionSetup';
import AiFlowEmailActionSetup from './action/communication/AiFlowEmailActionSetup';
import AiFlowHttpActionSetup from './action/communication/AiFlowHttpActionSetup';
import AiFlowEmbeddingActionSetup from './action/llm/AiFlowEmbeddingActionSetup';
import AiFlowOperationsActionSetup from './action/AiFlowOperationsActionSetup';
import AiFlowFinetuneActionSetup from './action/llm/AiFlowFinetuneActionSetup';
import AiFlowTriggerActionSetup from './action/trigger/AiFlowTriggerActionSetup';
import AiFlowBranchActionSetup from './action/branching/AiFlowBranchActionSetup';
import AiFlowBranchesActionSetup from './action/branching/AiFlowBranchesActionSetup';
import AiFlowConditionActionSetup from './action/condition/AiFlowConditionActionSetup';
import AiFlowDelayActionSetup from './AiFlowDelayActionSetup';
import AiFlowTextToSpeechActionSetup from './action/generate/AiFlowTextToSpeechActionSetup';
import AiFlowSpeechToTextActionSetup from './action/generate/AiFlowSpeechToTextActionSetup';
import AiFlowJudgeActionSetup from './action/llm/AiFlowJudgeActionSetup';

export default function AiFlowActionSetup() {
  const dispatch = useDispatch();
  const [promptInput, setPromptInput] = useState<string>('');
  const [noteInput, setNoteInput] = useState<string>('');
  const [configuration, setConfiguration] = useState<ConfigurationLogic>();
  const [selectedAction, setSelectedAction] = useState<ActionInfo>();
  const [lastSelectedItem, setLastSelectedItem] = useState<AiFlowItemLogic>();
  const [selectedActionGroup, setSelectedActionGroup] = useState<string>('');
  const actionGroups = useSelector((state) => state.aiFlows.actionGroups);
  const [selectedItemLabel, setSelectedItemLabel] = useState<string>('');
  const [actionName, setActionName] = useState<string>('');
  const [lastSavedItem, setLastSavedItem] = useState<AiFlowItemLogic>();
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const lastSavedAiFlow = useSelector((state) => state.aiFlows.lastSavedSelectedAiFlow);
  const { enqueueSnackbar } = useSnackbar();
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const updateSelectedItemActionName = useCallback(
    (newActionName: string) => {
      const updatedItem = {
        ...selectedItem,
        name: newActionName,
      };
      dispatch(updateTempSelectedAiFlowItem(updatedItem));
    },
    [selectedItem, dispatch]
  );

  const updateSelectedItemNote = useCallback(
    (note: string) => {
      const updatedItem = {
        ...selectedItem,
        inputData: {
          ...selectedItem?.inputData,
          actionInputData: {
            ...selectedItem?.inputData?.actionInputData,
          },
          note,
        },
      };
      dispatch(updateTempSelectedAiFlowItem(updatedItem));
    },
    [selectedItem, dispatch]
  );

  useEffect(() => {
    if (selectedItem) {
      // if (!_.isEqual(selectedItem, lastSelectedItem)) {
      //   setLastSelectedItem(selectedItem);
      //   setPromptInput('');
      //   setConfiguration(undefined);
      // }
      let tmpActionName = '';
      if (selectedItem.header && selectedItem.header !== lastSelectedItem?.header) {
        setLastSelectedItem(_.cloneDeep(selectedItem));
        const selectedItemHeader = selectedItem.header;
        const tmpActionGroup = actionGroups.find(
          (group) =>
            group.actions &&
            group.actions?.find(
              (groupAction) => groupAction && groupAction.header === selectedItemHeader
            ) !== undefined
        );
        const tmpAction = tmpActionGroup?.actions?.find((a) => a.header === selectedItemHeader);
        setSelectedAction(tmpAction);
        setSelectedActionGroup(tmpActionGroup?.label ?? '');
        setSelectedItemLabel(
          `${selectedItem.sequence}. ${tmpActionGroup?.label} ${tmpAction?.actionLabel}`
        );
        tmpActionName = `${tmpActionGroup?.label}_${tmpAction?.actionLabel}_${selectedItem.sequence}`;
        setActionName(tmpActionName);
      }
      if (selectedItem.name !== undefined) {
        setActionName(selectedItem.name as string);
      }
      if (selectedItem.inputData) {
        if (selectedItem.inputData.note) {
          setNoteInput(selectedItem.inputData.note);
        }
        if (selectedItem.inputData.actionInputData) {
          if (selectedItem.inputData.actionInputData.promptInput) {
            setPromptInput(selectedItem.inputData.actionInputData.promptInput);
          }
          if (selectedItem.inputData.actionInputData.configuration) {
            setConfiguration(selectedItem.inputData.actionInputData.configuration);
          }
        }
      }
    }
  }, [
    selectedItem,
    actionGroups,
    lastSelectedItem,
    updateSelectedItemActionName,
    selectedActionGroup,
    selectedAction,
  ]);

  const itemNameAlreadyExist = (name: string) => {
    if (!selectedItem?.id) {
      return false;
    }
    const existingItem = selectedAiFlow?.items
      ?.filter((i) => i.aiFlowVersionId === selectedItem?.aiFlowVersionId)
      .find(
        (item) =>
          item.name === name &&
          item.id !== selectedItem?.id &&
          item.sequence !== selectedItem?.sequence
      );
    return !!existingItem;
  };

  const AiFlowSchema: any = Yup.object().shape({
    actionName: Yup.string()
      .required('Required')
      .test(
        'uniqueName',
        'Name already exists',
        (value: any) => !itemNameAlreadyExist(value as string)
      ),
  });

  useEffect(() => {
    const lastSaved = lastSavedAiFlow?.items?.find(
      (item) => item.sequence === selectedItem?.sequence
    );
    setLastSavedItem(lastSaved);
  }, [lastSavedAiFlow, selectedItem]);

  const defaultValues = {
    actionName: selectedItem?.name ?? '',
  };

  const methods = useForm<any>({
    resolver: yupResolver(AiFlowSchema),
    mode: 'all',
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    trigger,
    setValue,
  } = methods;

  return (
    <>
      <Stack spacing={2} sx={{ mx: 0.25 }}>
        <Stack
          direction="row"
          sx={{
            placeItems: 'center',
          }}
          justifyContent="space-between"
          spacing={2.5}
        >
          {/* <Typography variant="h6">{selectedItemLabel}</Typography> */}
        </Stack>
        <FormProvider methods={methods}>
          <RHFTextField
            sx={{ px: 1, mt: '0px !important' }}
            fullWidth
            id="actionName"
            size="small"
            disabled={runsMode}
            name="actionName"
            label="Action Name"
            value={actionName}
            onChange={(e) => {
              setActionName(e.target.value);
              setValue('actionName', e.target.value);
              updateSelectedItemActionName(e.target.value);
            }}
            helperText="This field can be used to reference this action output in other actions"
            multiline
            rows={1}
          />
        </FormProvider>
        {selectedAction?.actionLabel !== 'Branch' && selectedAction?.actionLabel !== 'Branches' && (
          <RHSelect
            //  zIndex={3410}
            disabled={runsMode}
            startAdornmentIcon={selectedAction?.icon as string}
            onClick={(event) => {
              event.stopPropagation();

              dispatch(
                setSidebarPopoverOpen({
                  element: event.currentTarget as HTMLElement,
                  position: 'center',
                })
              );
            }}
            sx={{ px: 1 }}
            //   width={maxWidth}
            key="selectConfiguration"
            name="selectConfiguration"
            size="small"
            label="Action Type"
            value={selectedAction?.actionLabel || ''}
            onChange={(event) => {}}
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem onClick={(event) => {}} value={selectedAction?.actionLabel as string}>
              <span>{selectedAction?.actionLabel}</span>
            </MenuItem>
          </RHSelect>
        )}
        {selectedItem &&
          (selectedItem as any).sequence > 1 &&
          selectedItem?.header !== 'Branches' && <PreviousActionsChips />}
        {/* {selectedAction?.header === 'VisionaryAnalysis' && (
          <PreviousActionsSelect label = "Select Image Source" onPreviousActionsSelect={() => {}} />
        )} */}
        {selectedActionGroup !== 'System' &&
          selectedActionGroup !== 'Functional' &&
          selectedActionGroup !== 'Communication' &&
          selectedAction?.header !== 'Transcribe' &&
          selectedAction?.header !== 'TextToSpeech' &&
          selectedActionGroup !== 'LLM' && (
            <AiFlowGenerateActionSetup
              onPromptInputChange={(prompt) => {
                setPromptInput(prompt);
              }}
            />
          )}
        {selectedAction?.header === 'Input' && <AiFlowInputActionSetup />}
        {selectedAction?.header === 'Transcribe' && <AiFlowSpeechToTextActionSetup />}
        {selectedAction?.header === 'TextToSpeech' && <AiFlowTextToSpeechActionSetup />}
        {selectedAction?.header === 'Trigger' && <AiFlowTriggerActionSetup />}
        {selectedAction?.header === 'Delay' && <AiFlowDelayActionSetup />}
        {selectedAction?.header === 'Condition' && <AiFlowConditionActionSetup />}
        {selectedAction?.header === 'Scrapper' && <AiFlowScrapperActionSetup />}
        {selectedAction?.header === 'Http' && <AiFlowHttpActionSetup />}
        {selectedAction?.header === 'Email' && <AiFlowEmailActionSetup />}
        {selectedAction?.header === 'Combine' && <AiFlowCombineActionSetup />}
        {selectedAction?.header === 'Validator' && <AiFlowValidatorActionSetup />}
        {selectedAction?.header === 'FineTune' && <AiFlowFinetuneActionSetup />}
        {selectedAction?.header === 'Embeddings' && <AiFlowEmbeddingActionSetup />}
        {selectedAction?.header === 'Judge' && <AiFlowJudgeActionSetup />}
        {selectedAction?.header === 'Extractor' && <AiFlowExtractorActionSetup />}
        {selectedAction?.header === 'Branch' && <AiFlowBranchActionSetup />}
        {selectedAction?.header === 'Branches' && <AiFlowBranchesActionSetup />}
        {selectedAction?.header === 'Locator' && <AiFlowLocatorActionSetup />}
        {selectedAction?.header === 'Transformer' && <AiFlowTransformerActionSetup />}
        {selectedAction?.header === 'Segmentation' && <AiFlowSegmentActionSetup />}
        {selectedAction?.header === 'Export' && <AiFlowExportActionSetup />}
        {selectedAction?.header === 'Operations' && <AiFlowOperationsActionSetup />}
        {/* {selectedActionGroup === 'Transform' && <AiFlowTransformActionSetup />} */}
        {selectedActionGroup !== 'System' &&
          selectedActionGroup !== 'Functional' &&
          selectedActionGroup !== 'Communication' &&
          selectedActionGroup !== 'LLM' &&
          selectedAction?.header !== 'Transcribe' &&
          selectedAction?.header !== 'TextToSpeech' &&
          (!configuration ||
            (configuration && configuration?.modelProvider?.includes('OpenAI'))) && (
            <>
              <Paper
                key=""
                variant="outlined"
                sx={{
                  py: 1.5,
                  mx: 1,
                  display: 'flex',
                  position: 'relative',
                  flexDirection: 'column',
                  flexGrow: 1,
                  height: '100%',
                }}
              >
                <Typography variant="subtitle2" sx={{ textAlign: 'center', paddingBottom: 1 }}>
                  System Prompt
                </Typography>
                <Divider sx={{ mb: 1 }} />
                <PromptEditor
                id = "systemPrompt"
                  height={150}
                  promptInput={selectedAction?.systemPrompt ?? ''}
                  readonly
                />
              </Paper>
            </>
          )}
        {/* <TextField
          sx={{ px: 1, mt: '4px !important' }}
          fullWidth
          disabled
          id="system"
          name="system"
          label="System"
          value={selectedAction?.systemPrompt ?? ''}
          helperText="This field influences how the AI interprets and responds to your input."
          multiline
          rows={10}
        /> */}
        <Stack direction="row" spacing={2}>
          {/* <LoadingButton
            fullWidth
            disabled={_.isEqual(selectedItem, lastSavedItem) || checkInvalidItem()}
            loading={aiFlowsLoading}
            variant="outlined"
            size="large"
            startIcon={<Iconify icon="material-symbols:save-outline" />}
            sx={{
              maxWidth: { md: 180 },
              maxHeight: { md: 40 },
            }}
            onClick={() => {
              if (selectedAction) {
                dispatch(updateAiFlowItem(enqueueSnackbar));
              }
            }}
          >
            Save
          </LoadingButton> */}

          {selectedActionGroup &&
            selectedActionGroup !== 'System' &&
            selectedActionGroup !== 'Functional' &&
            selectedAction?.header !== 'Transcribe' &&
          selectedAction?.header !== 'TextToSpeech' &&
            selectedActionGroup !== 'Communication' &&
            selectedActionGroup !== 'LLM' && (
              <ConfigurationButton
                type={selectedAction?.header as string}
                inputConfiguration={configuration}
                onConfirmConfiguration={(config: ConfigurationLogic) => {
                  setConfiguration(config);
                  dispatch(
                    updateSelectedAiFlowItem({
                      ...selectedItem,
                      inputData: {
                        ...selectedItem?.inputData,
                        actionInputData: {
                          ...selectedItem?.inputData?.actionInputData,
                          configuration: config,
                        },
                      },
                    })
                  );
                }}
              />
            )}
        </Stack>

        {/* <Divider sx={{ borderStyle: 'dashed' }} />
        <TextField
          fullWidth
          disabled={runsMode}
          label="Note(Optional)"
          multiline
          value={noteInput}
          onChange={(e: any) => {
            setNoteInput(e.target.value);
            updateSelectedItemNote(e.target.value);
          }}
          rows={1}
          placeholder="Prompt"
          sx={{ px: 1 }}
        /> */}
      </Stack>
    </>
    // <Stack spacing={2.5}>
    //   <Stack
    //     direction="row"
    //     sx={{
    //       placeItems: 'center',
    //     }}
    //     justifyContent="space-between"
    //     spacing={2.5}
    //   >
    //     <Button
    //       size="small"
    //       color="inherit"
    //       disableRipple
    //       sx={{ minWidth: 100, justifyContent: 'flex-start' }}
    //       onClick={() => dispatch(selectAiFlowItem(null))}
    //       startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
    //     >
    //       Back
    //     </Button>
    //     <Typography variant="h6">{selectedItemLabel}</Typography>
    //     {!fullscreen && (
    //       <IconButton onClick={() => dispatch(setFullscreen(true))}>
    //         <Iconify width={30} height={30} icon="material-symbols:fullscreen" />
    //       </IconButton>
    //     )}
    //     {fullscreen && <div/>}
    //   </Stack>
    //   <Stack direction="row" alignContent="center" display="flex" alignItems="center" spacing={1}>
    //     <Switch onChange={useLastActionOutput.onToggle} sx={{ mx: 1 }} />
    //     <Typography variant="subtitle1">Use prior output as context</Typography>
    //   </Stack>
    //   <PromptPaperList
    //     promptInput={promptInput}
    //     setPromptInput={(prompt: string) => {
    //       setPromptInput(prompt);
    //       dispatch(
    //         updateSelectedAiFlowItem({
    //           ...selectedItem,
    //           inputData: { configuration, promptInput: prompt },
    //         })
    //       );
    //     }}
    //     prompts={ideasPromptSamples}
    //   />
    //   <PromptInput
    //     promptInput={promptInput}
    //     setPromptInput={(prompt: string) => {
    //       setPromptInput(prompt);
    //       dispatch(
    //         updateSelectedAiFlowItem({
    //           ...selectedItem,
    //           inputData: {
    //             ...selectedItem?.inputData,
    //             actionInputData: {
    //               ...selectedItem?.inputData?.actionInputData,
    //               promptInput: prompt,
    //             },
    //           },
    //         })
    //       );
    //     }}
    //   />
    //   <Stack direction="row" spacing={2}>
    //     <Button
    //       fullWidth
    //       variant="outlined"
    //       size="large"
    //       startIcon={<Iconify icon="material-symbols:save-outline" />}
    //       sx={{
    //         minWidth: { md: 150 },
    //         maxHeight: { md: 40 },
    //       }}
    //       onClick={() => {
    //         if (selectedAction) {
    //           dispatch(
    //             updateSelectedAiFlowItem({
    //               ...selectedItem,
    //               inputData: {
    //                 configuration,
    //                 promptInput,
    //               },
    //             })
    //           );
    //         }
    //       }}
    //     >
    //       Save
    //     </Button>
    //     <ConfigurationButton type={selectedAction?.actionType as string} />
    //   </Stack>
  );
}
