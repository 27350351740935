import { Typography, useTheme } from "@mui/material";
import { format } from "date-fns";

interface MessageTimestampProps {
  timestamp: Date;
  visible: boolean;
  darkMode: boolean;
}

export function MessageTimestamp({ timestamp, visible, darkMode }: MessageTimestampProps) {
  const theme = useTheme();
  
  return (
    <Typography 
      variant="caption" 
      sx={{ 
        display: "block", 
        mt: 1, 
        color: darkMode? 'white' : theme.palette.text.secondary,
        fontSize: { xs: '0.7rem', sm: '0.75rem' },
        opacity: visible ? 1 : 0,
        transition: 'opacity 0.2s ease-in-out',
        pointerEvents: 'none'
      }}
    >
      {format(timestamp, "MMM d, yyyy h:mm a")}
    </Typography>
  );
}