import {
  Box,
  Button,
  Card,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { useDispatch, useSelector } from 'src/redux/store';
import { useEffect, useState } from 'react';
import AiFlowSidebarItem from './AiFlowSidebarItem';
import { AiFlowItemType } from '../enums/AiFlowItemType.enum';
import { AiFlowActionGroupLogic } from 'src/api';
import { resetSelectedAiFlow } from 'src/redux/slices/aiflows';
import GenAiActionDialog from './custom-action/GenAiActionDialog';
import { useBoolean } from 'src/hooks/use-boolean';

interface Props {}

export default function AiFlowSideBar() {
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const fullScreen = useSelector((state) => state.aiFlows.fullscreen);
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const actionGroups = useSelector((state) => state.aiFlows.actionGroups);
  const [searchValue, setSearchValue] = useState<string>('');
  const openCustomActionDialog = useBoolean(false);
  const [filteredActionGroups, setFilteredActionGroups] =
    useState<AiFlowActionGroupLogic[]>(actionGroups);
  const [selectedItemLabel, setSelectedItemLabel] = useState<string>('');
  const [actionsUniqueIndex, setActionsUniqueIndex] = useState<{ [header: string]: number }>({});
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedItem && selectedItem.header) {
      const selectedItemHeader = selectedItem.header;
      const tmpActionGroup = actionGroups.find(
        (group) =>
          group.actions &&
          group.actions?.find(
            (groupAction) => groupAction && groupAction.header === selectedItemHeader
          ) !== undefined
      );
      const tmpAction = tmpActionGroup?.actions?.find((a) => a.header === selectedItemHeader);
      setSelectedItemLabel(`${tmpActionGroup?.label} ${tmpAction?.actionLabel}`);
    }
  }, [selectedItem, actionGroups]);

  useEffect(() => {
    if (actionGroups && actionGroups.length > 0) {
      const tmpActionsUniqueIndex: { [header: string]: number } = {};
      let ind = 1001;
      actionGroups.forEach((group) => {
        if (group.actions && group.actions.length > 0) {
          group.actions.forEach((action, index) => {
            let header = action.header;
            if (action.header === 'Custom') {
              header = `${group.label}_${action.actionLabel}`;
            }
            tmpActionsUniqueIndex[header as string] = ind++;
          });
        }
      });
      setActionsUniqueIndex(tmpActionsUniqueIndex);
      setFilteredActionGroups(actionGroups);
    }
  }, [actionGroups]);

  return (
    <>
      <Card
        sx={{
          p: 3,
          zIndex: 3100,
          minWidth: runsMode ? 710 : 420,
          maxWidth: runsMode ? 710 : 420,
          minHeight: '100%',
          height: '100%',
        }}
      >
        <>
          <Stack spacing={4}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              {/* <Typography variant="h4" sx={{ textDecoration: 'underline' }}>
                  Drag & Drop
                </Typography> */}
              {/* <Searchbar /> */}
              <TextField
                placeholder="Search Actions"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  if (e.target.value && e.target.value.length > 0) {
                    const filteredGroups = actionGroups.map((group) => {
                      const filteredActions = group.actions?.filter((action) => {
                        const actionLabel = action.actionLabel?.toLowerCase();
                        const searchValueTmp = e.target.value.toLowerCase();
                        return actionLabel?.includes(searchValueTmp);
                      });
                      return { ...group, actions: filteredActions };
                    });
                    setFilteredActionGroups(filteredGroups);
                  } else {
                    setFilteredActionGroups(actionGroups);
                  }
                }}
                sx={{ minWidth: '100%' }}
                InputProps={{
                  //    ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
                    </InputAdornment>
                  ),
                  endAdornment: searchValue && (
                    <InputAdornment position="end">
                      <Iconify
                        onClick={() => {
                          setFilteredActionGroups(actionGroups);
                          setSearchValue('');
                        }}
                        icon="eva:close-fill"
                        sx={{ mr: 1, color: 'text.disabled', cursor: 'pointer' }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={searchValue}
              />
              {/* {!fullScreen && (
                  <IconButton onClick={() => dispatch(setFullscreen(true))}>
                    <Iconify width={30} height={30} icon="material-symbols:fullscreen" />
                  </IconButton>
                )} */}
            </Stack>

            {/* <Box
              gap={3}
              sx={{ height: '100%', width: '100%' }}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(2, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >
              <AiFlowSidebarItem type="Validator" key={5000} sequence="5000" />
              <AiFlowSidebarItem type="Delay" key={6000} sequence="6000" />
            </Box> */}
            <Scrollbar sx={{ height: 445 }}>
              {filteredActionGroups.map((group) => (
                <>
                  {group && group.actions && group.actions.length > 0 && (
                    <Stack sx={{ mb: 2 }}>
                      <Stack>
                        <Typography variant="h6">{group?.label} Actions</Typography>

                        <Paper
                          variant="outlined"
                          sx={{
                            p: 1,
                            width: 1,
                            minHeight: 10,
                            position: 'relative',
                          }}
                        >
                          <Box
                            gap={3}
                            sx={{ height: '100%', width: '100%' }}
                            display="grid"
                            gridTemplateColumns={{
                              xs: 'repeat(2, 1fr)',
                              sm: 'repeat(2, 1fr)',
                              md: 'repeat(3, 1fr)',
                            }}
                          >
                            {group &&
                              group.actions &&
                              group.actions.map((actionInfo, index) => (
                                <>
                                  {!actionInfo?.hidden &&
                                    actionsUniqueIndex &&
                                    (actionsUniqueIndex[actionInfo.header as string] ||
                                      actionsUniqueIndex[
                                        `${group.label}_${actionInfo.actionLabel}`
                                      ]) && (
                                      <AiFlowSidebarItem
                                        type="Action"
                                        key={
                                          actionInfo.header === 'Custom'
                                            ? actionsUniqueIndex[
                                                `${group.label}_${actionInfo.actionLabel}`
                                              ]
                                            : actionsUniqueIndex[actionInfo.header as string]
                                        }
                                        sequence={
                                          actionInfo.header === 'Custom'
                                            ? actionsUniqueIndex[
                                                `${group.label}_${actionInfo.actionLabel}`
                                              ].toString()
                                            : actionsUniqueIndex[
                                                actionInfo.header as string
                                              ].toString()
                                        }
                                        actionInfo={actionInfo}
                                      />
                                    )}
                                </>
                              ))}
                          </Box>
                        </Paper>
                      </Stack>
                    </Stack>
                  )}
                </>
              ))}
            </Scrollbar>

            {/* {actions.map((action) => (
              <>
                <Paper
                  variant="outlined"
                  sx={{
                    p: 1,
                    width: 1,
                    minHeight: 10,
                    position: 'relative',
                  }}
                >
                  <Box
                    gap={3}
                    sx={{ height: '100%', width: '100%' }}
                    display="grid"
                    gridTemplateColumns={{
                      xs: 'repeat(2, 1fr)',
                      sm: 'repeat(2, 1fr)',
                      md: 'repeat(2, 1fr)',
                    }}
                  >
                    <Card
                      sx={{
                        p: 2,
                        borderRadius: 1,
                        borderColor: 'primary.main',
                        cursor: 'pointer',
                        '&:hover': { border: '1px solid', borderColor: 'primary.main' },
                        borderWidth: 2,
                        border: '1px solid',
                      }}
                    >
                      <Stack direction="row" alignItems="center">
                        <BiggerStyledIcon>{ICONS.analysis}</BiggerStyledIcon>

                        <Typography sx={{ ml: 1 }} variant="subtitle2">
                          Content Analysis
                        </Typography>
                      </Stack>
                    </Card>
                    <Card
                      sx={{
                        p: 2,
                        borderRadius: 1,
                        borderColor: 'primary.main',
                        cursor: 'pointer',
                        '&:hover': { border: '1px solid', borderColor: 'primary.main' },
                        borderWidth: 2,
                        border: '1px solid',
                      }}
                    >
                      <Stack direction="row" alignItems="center">
                        <BiggerStyledIcon>{ICONS.analysis}</BiggerStyledIcon>

                        <Typography sx={{ ml: 1 }} variant="subtitle2">
                          Content Analysis
                        </Typography>
                      </Stack>
                    </Card>
                    <Stack direction="row" alignItems="center">
                      <BiggerStyledIcon>{ICONS.analysis}</BiggerStyledIcon>

                      <Typography sx={{ ml: 1 }} variant="subtitle2">
                        Content Analysis
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <BiggerStyledIcon>{ICONS.analysis}</BiggerStyledIcon>

                      <Typography sx={{ ml: 1 }} variant="subtitle2">
                        Content Analysis
                      </Typography>
                    </Stack>
                  </Box>
                </Paper>
              </>
            ))} */}
            <Tooltip
             PopperProps={{
              style: {
                zIndex: 3300,
              },
            }}
            title="Add Generative AI Action">
              <Button
                color="inherit"
                variant="outlined"
                sx={{ flexShrink: 0, mb: 2 }}
                startIcon={<Iconify icon="bx:customize" />}
                onClick={openCustomActionDialog.onTrue}
              >
                Add Generative AI Action
              </Button>
            </Tooltip>
          </Stack>
        </>
        <GenAiActionDialog
          open={openCustomActionDialog.value}
          onClose={openCustomActionDialog.onFalse}
        />
      </Card>
    </>
  );
}
