import { Box, TextField, IconButton, Typography, useTheme } from '@mui/material';
import {  Close, Send } from '@mui/icons-material';
import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'src/redux/store';
import { AgentLogic, DeployedAgentLogic } from 'src/api';
import FileUpload from '../FileUpload';
import { darkModeColor } from './DarkModeColor';

interface ChatInputProps {
  onSend: (message: string) => void;
  disabled?: boolean;
  selectedAgent : DeployedAgentLogic;
}

export default function ChatInputPublic({ onSend, disabled, selectedAgent }: ChatInputProps) {
  const theme = useTheme();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [shouldFocus, setShouldFocus] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState<string>('#F7F7F8');
  const [suggestions, setSuggestions] = useState<string[]>([
    'Tell me more about your project',
    'Would you like to see some examples?',
    'How can I help you today?',
    'What specific features are you looking for?',
    'Would you like to explore the documentation?',
  ]);
  const [suggestionsEnabled, setSuggestionsEnabled] = useState(true);

  const handleSubmit = () => {
    if (message.trim() && !disabled) {
      onSend(message);
      setMessage('');
      setShouldFocus(true);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '24px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [message]);

  const handleSuggestionClick = (suggestion: string) => {
    setMessage(suggestion);
    if (textareaRef.current) {
      textareaRef.current.focus();
      setShouldFocus(true);
    }
  };

  // Effect to handle focus when disabled state changes
  useEffect(() => {
    if (!disabled && shouldFocus && textareaRef.current) {
      textareaRef.current.focus();
      setShouldFocus(false);
    }
  }, [disabled, shouldFocus]);

  useEffect(() => {
    if (selectedAgent) {
      setSuggestionsEnabled(
        selectedAgent.uiConfigurations?.agentChatUiConfigurations?.enableSmartSuggestions ?? true
      );
      setDarkMode(selectedAgent.uiConfigurations?.agentChatUiConfigurations?.darkMode ?? false);
      setSuggestions(
        selectedAgent.uiConfigurations?.agentChatUiConfigurations?.smartSuggestions ?? [
          'how are you today?',
        ]
      );
      setBackgroundColor(
        selectedAgent.uiConfigurations?.agentChatUiConfigurations?.backgroundColor ?? '#F7F7F8'
      );
    }
  }, [selectedAgent]);

  const handleFileSelect = (files: File[]) => {
    setSelectedFiles(prev => [...prev, ...files]);
  };

  const removeFile = (index: number) => {
    setSelectedFiles(prev => prev.filter((_, i) => i !== index));
  };

  return (
    <Box
      sx={{
        backgroundColor: darkMode ? darkModeColor : backgroundColor,
        borderTop: 1,
        borderColor: 'divider',
        
        //  bgcolor:  'background.paper',
            position: "fixed",
            bottom: 0,
            left: 0,
        width: '100%',
      }}
    >
      <Box
        sx={{
          maxWidth: '48rem',
          backgroundColor: darkMode ? darkModeColor : backgroundColor,
          mx: 'auto',
          p: { xs: 2, md: 3 },
          position: 'relative',
        }}
      >
        {suggestionsEnabled && suggestions.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              //       backgroundColor: darkMode ? '#27272A' : 'background.paper',
              gap: 1,
              mb: 2,
              flexWrap: 'wrap',
            }}
          >
            {suggestions.map((suggestion, index) => (
              <Typography
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                sx={{
                  cursor: 'pointer',
                  bgcolor: darkMode ? darkModeColor : theme.palette.background.paper,
                  border: 1,
                  borderColor: darkMode? '#27272A' : theme.palette.divider,
                  borderRadius: 1,
                  color: darkMode ? 'white' : theme.palette.text.primary,
                  px: 2,
                  py: 1,
                  fontSize: '0.875rem',
                  '&:hover': {
                    bgcolor: theme.palette.action.hover,
                  },
                }}
              >
                {suggestion}
              </Typography>
            ))}
          </Box>
        )}
         {selectedFiles.length > 0 && (
          <Box sx={{ 
            mb: 2, 
            p: 2, 
            borderRadius: 1,
            border: 1,
            backgroundColor: darkMode ? '#18181B' : backgroundColor,
            borderColor: theme.palette.divider,
            display: 'flex',
            color: darkMode ? 'white' : theme.palette.text.primary,
            flexWrap: 'wrap',
            gap: 2
          }}>
            {selectedFiles.map((file, index) => (
              <Box 
                key={index}
                sx={{
                  position: 'relative',
                  backgroundColor: darkMode ? '#18181B' : backgroundColor,
                  width: 'fit-content'
                }}
              >
                <img 
                  src={URL.createObjectURL(file)} 
                  alt={`Preview ${index + 1}`}
                  style={{ 
                    width: 80, 
                    height: 80, 
                    
                    objectFit: 'cover',
                    borderRadius: 4
                  }} 
                />
                <IconButton
                  size="small"
                  onClick={() => removeFile(index)}
                  sx={{
                    position: 'absolute',
                    top: -8,
                    right: -8,
                    backgroundColor: darkMode ? '#18181B' : 'background.paper',
                    // bgcolor: 'background.paper',
                    color: darkMode ? 'white' : theme.palette.text.primary,
                    border: 1,
                    borderColor: 'divider',
                    '&:hover': {
                      bgcolor: 'action.hover'
                    }
                  }}
                >
                  <Close sx={{ fontSize: 16 }} />
                </IconButton>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    display: 'block',
                    textAlign: 'center',
                    mt: 0.5,
                    maxWidth: 80,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {file.name}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-end',
            width: '100%',
            backgroundColor: darkMode ? '#27272A' : 'background.paper',
            borderRadius: 1,
            border: 1,
            borderColor: darkMode ? '#27272A' : theme.palette.action.hover,
            boxShadow: '0 0 15px rgba(0,0,0,0.1)',
          }}
        >
          <FileUpload darkMode = {darkMode} onFileSelect={handleFileSelect} disabled={disabled} />
          <TextField
            multiline
            maxRows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            InputProps={{
              disableUnderline: true,
            }}
            placeholder="Send a message..."
            disabled={disabled}
            inputRef={textareaRef}
            variant="outlined"
            sx={{
              bgcolor: darkMode ? '#27272A' : theme.palette.background.paper,
              color: darkMode ? 'white' : theme.palette.text.primary,
              width: '100%',
              '& .MuiOutlinedInput-root': {
                bgcolor: 'transparent',
                color: darkMode ? 'white' : theme.palette.text.primary,
                '& fieldset': {
                  border: 'none',
                  bgcolor: 'transparent',
                },
              },
              '& .MuiInputBase-root': {
                bgcolor: 'transparent',
                '& textarea': {
                  bgcolor: 'transparent',
                },
              },
              '& .MuiInputBase-input': {
                p: 1.5,
                minHeight: '24px',
                resize: 'none',
                bgcolor: 'transparent',
              },
              // Override any potential white backgrounds
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
                bgcolor: 'transparent',
              },
              // Target the actual textarea element
              '& textarea.MuiInputBase-input': {
                bgcolor: 'transparent',
              },
            }}
          />
          <IconButton
            onClick={handleSubmit}
            disabled={!message.trim() || disabled}
            sx={{
              position: 'absolute',
              right: 1,
              bottom: 1,
              bgcolor: '#10A37F',
              color: 'white',
              '&:hover': {
                bgcolor: '#1A7F64',
              },
              width: 32,
              height: 32,
              '& .MuiSvgIcon-root': {
                fontSize: 16,
              },
            }}
          >
            <Send />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}
