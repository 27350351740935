import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'src/redux/store';
import { useSnackbar } from 'src/components/snackbar';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { RHRadioGroup } from 'src/components/hook-form/RHFRadioGroup';
import {
  addAiFlowItemBeforeSequence,
  selectAiFlowItem,
  setNodeClickedSequenceInfo,
} from 'src/redux/slices/aiflows';
import _ from 'lodash';

interface Props {
  onClose: () => void;
  open: boolean;
}

export default function NewBranchDialog({ onClose, open }: Props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [branchName, setBranchName] = useState<string>('');
  const [branchType, setBranchType] = useState<string>('Run Always');
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const [branchTypes, setBranchTypes] = useState([
    { label: 'Run Always', value: 'Run Always', disabled: false },
    { label: 'Condition', value: 'Condition', disabled: false },
    {
      label: 'Failover',
      value: 'Failover',
      disabled: !!selectedAiFlow?.items?.find(
        (i) =>
          i.header === 'Branch' &&
          i.inputData?.actionInputData?.branchingInputData?.branchInputData?.branchType ===
            'Failover'
      ),
    },
  ]);

  return (
    <Dialog sx={{ zIndex: 4500 }} open={open} onClose={onClose}>
      <DialogTitle>Add Branch</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <TextField
            autoFocus
            margin="dense"
            id="branchName"
            value={branchName}
            onChange={(e) => setBranchName(e.target.value)}
            label="Branch Name"
            type="branchName"
            fullWidth
            variant="standard"
          />

          <RHRadioGroup
            value={branchType} // upload/url
            onChange={(event) => {
              setBranchType(event.target.value);
            }}
            row
            spacing={4}
            name="branchTypes"
            options={branchTypes}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            

            const branchItems = selectedAiFlow?.items?.filter(
              (item) => item.header === 'Branch' && item.parentSequence === selectedItem?.sequence
            );
            const branchItemWithMaxSequence = branchItems?.reduce((prev, current) =>
              (prev.sequence as number) > (current.sequence as number) ? prev : current
            );
            const itemsBelongToBranch: any[] = [];
            selectedAiFlow
              ?.items!.filter(
                (item: any) =>
                  (item.sequence as number) > (branchItemWithMaxSequence?.sequence as number)
              )
              .some((item: any) => {
                if (item.parentSequence + 1 === item.sequence) {
                  itemsBelongToBranch.push(item);
                  return false; // continue to the next iteration
                } else {
                  return true; // exit the loop
                }
              });
            const itemsBelongToBranchWithBranchItem = [
              branchItemWithMaxSequence,
              ...itemsBelongToBranch,
            ];
            const item = {
              header: 'Branch',
              sequence:
                (itemsBelongToBranchWithBranchItem[itemsBelongToBranchWithBranchItem.length - 1]
                  .sequence as number) + 1,
              parentSequence: branchItemWithMaxSequence?.parentSequence,
              aiFlowId: selectedAiFlow?.id as number,
              inputData: {
                actionInputData: {
                  branchingInputData: {
                    branchInputData: {
                      branchName,
                      branchType,
                    },
                  },
                },
              },
            } as any;
            const newItem = _.cloneDeep(item);
            
            dispatch(selectAiFlowItem(null));
            onClose();
            dispatch(
              setNodeClickedSequenceInfo({
                addBeforeSequence: newItem.sequence,
              })
            );
            setTimeout(() => {
              dispatch(addAiFlowItemBeforeSequence(newItem));
            }, 1);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
