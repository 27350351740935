import {
  Typography,
  Paper,
} from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import Iconify from 'src/components/iconify';
import { useEffect, useRef, useState } from 'react';
import { AgentFieldLogic, AgentRunLogic, AiFlowRunLogic, DeployedAgentLogic } from 'src/api';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import LoadingDots from 'src/common/widgets/LoadingDots';
import { darkModeColor } from '../chat/public/DarkModeColor';
import AiAgentUiField from '../fields/AiAgentUiField';
import AiAgentUiFieldPublic from './AiAgentUiFieldPublic';

interface Params {
  deployedAgent: DeployedAgentLogic;
}

export default function AgentFormUiPublic({ deployedAgent }: Params) {
  const [darkMode, setDarkMode] = useState(false);
  const [text, setText] = useState('Click to edit this heading');
  const [outputBackgroundColor, setOutputBackgroundColor] = useState<string>('#f6f6f6');
  const [formBackgroundColor, setFormBackgroundColor] = useState<string>('#f6f6f6');
  const editableRef = useRef<any>(null);
  const [sortedAgentFields, setSortedAgentFields] = useState<AgentFieldLogic[]>([]);
  const [selectedAgentLastRun, setSelectedAgentLastRun] = useState<AgentRunLogic>();
  const [selectedAgentLastRunLogic, setSelectedAgentLastRunLogic] = useState<AiFlowRunLogic>();

  const handleBlur = () => {
    // When user finishes editing, sync with state
    const currentText = editableRef.current?.innerText;
    setText(currentText);
  };

  // useEffect(() => {
  //   if (selectedAgent && runs && agentRuns) {
  //     const agentRunsTemp = agentRuns[selectedAgent?.id as number];
  //     if (agentRunsTemp && agentRunsTemp.length > 0) {
  //       console.log('agentRunsTemp', agentRunsTemp);
  //       const clonedAgentRuns = _.cloneDeep(agentRunsTemp);
  //       const sortedAgentRunsByDate = clonedAgentRuns.sort(
  //         (a: AgentRunLogic, b: AgentRunLogic) =>
  //           new Date(b?.runDate as Date).getTime() - new Date(a?.runDate as Date).getTime()
  //       );
  //       debugger;
  //       if (sortedAgentRunsByDate && sortedAgentRunsByDate.length > 0) {

  //         const lastAgentRun = sortedAgentRunsByDate[0];
  //         setSelectedAgentLastRun(lastAgentRun);
  //         const tmpRun = runs.find((run) => run?.id === lastAgentRun?.runId);
  //         setSelectedAgentLastRunLogic(tmpRun);
  //       }
  //     }
  //   }
  // }, [runs, selectedAgent, agentRuns]);

  const isRunInProgress =
    selectedAgentLastRunLogic?.status === 'InProgress' ||
    selectedAgentLastRunLogic?.status === 'Pending';

  useEffect(() => {
    // On mount or when text changes externally, ensure DOM text matches
    // Only run this if necessary, to avoid moving the caret
    if (editableRef.current && editableRef.current.innerText !== text) {
      editableRef.current.innerText = text;
    }
  }, [text]);

  useEffect(() => {
      const agentFields = deployedAgent?.uiConfigurations?.agentFormUiConfigurations?.agentFields;
      if (agentFields && agentFields.length > 0) {
        // sort fields by order
        const clonedAgentFields = _.cloneDeep(agentFields);
        const sortedFields = clonedAgentFields.sort(
          (a, b) => (a.order as number) - (b.order as number)
        );
        if (!_!.isEqual(sortedFields, sortedAgentFields)) {
          setSortedAgentFields(sortedFields);
        }
      }
    }, [sortedAgentFields, deployedAgent]);

  useEffect(() => {
    if (deployedAgent) {
      setDarkMode(deployedAgent?.uiConfigurations?.agentFormUiConfigurations?.darkMode as boolean);
    }
  }, [deployedAgent]);

  //   const handleInput = (event: any) => {
  //     setText(event.target.innerText);
  //   };

  return (
    <>
      <Container maxWidth={false}>
        {/* <Card
            sx={{
              p: 3,
              minHeight: { md: 500, xl: 620 },
              minWidth: { md: 800, xl: 800 },
              display: 'flex',
              flexDirection: 'column',
            }}
          > */}
        <Box
          sx={{
            display: 'flex',
            mt: 2,
            gap: 1,
            height: '100%',
          }}
        >
          <Paper
            elevation={3}
            sx={{
              flex: 1,
              p: 3,
              backgroundColor: darkMode ? darkModeColor : formBackgroundColor,
              minHeight: { md: 500, xl: 800 },
              maxHeight: { md: 500, xl: 800 },
              minWidth: { md: 600, xl: 700 },
              overflow: 'auto',
              position: 'relative',
            }}
          >
            <Stack spacing={3.5}>
              {sortedAgentFields.map((field: AgentFieldLogic, index: number) => (
                <AiAgentUiFieldPublic darkMode={darkMode} key={field.order} index={index} field={field} />
              ))}

              <LoadingButton
                loading={isRunInProgress}
                // disabled={!use}
                onClick={() => {}}
                variant="outlined" // give me start icon go next
                startIcon={<Iconify icon="codicon:debug-start" />}
                sx={{ color: 'primary.main', maxWidth: 200, marginTop: '38px !important' }}
              >
                Run
              </LoadingButton>
            </Stack>
          </Paper>
          <Paper
            elevation={3}
            sx={{
              flex: 1,
              p: 3,
              minHeight: { md: 500, xl: 800 },
              maxHeight: { md: 500, xl: 800 },
              minWidth: { md: 600, xl: 700 },
              backgroundColor: darkMode ? darkModeColor : outputBackgroundColor,
              overflow: 'auto',
            }}
          >
            <Stack spacing={3.5}>
              <Box sx={{ height: '100%' }}>
                <Typography
                  sx={{
                    color: darkMode ? 'white' : 'black',
                  }}
                  variant="h5"
                  component="h2"
                  gutterBottom
                >
                  Output
                </Typography>

                <Paper
                  variant="outlined"
                  sx={{
                    height: 'calc(100% - 40px)',
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    fontFamily: 'monospace',
                    overflow: 'auto',
                    color: darkMode ? 'white' : 'black',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                  }}
                >
                  {!selectedAgentLastRun?.output &&
                    !isRunInProgress &&
                    'No output yet. Press Run to start the agent.'}
                  {selectedAgentLastRun?.output && !isRunInProgress
                    ? selectedAgentLastRun?.output
                    : ''}
                  {isRunInProgress && (
                    <>
                      <Stack direction="row" spacing={0}>
                        <span>Processing</span>
                        <LoadingDots />
                      </Stack>
                    </>
                  )}
                </Paper>
              </Box>
            </Stack>
          </Paper>
        </Box>
      </Container>
    </>
  );
}
