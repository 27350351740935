import { useDroppable } from '@dnd-kit/core';
import { Typography, TextField, Button, Paper, FormControlLabel, Checkbox } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { AgentFieldLogic, AgentLogic } from 'src/api';
import { RHRadioGroup } from 'src/components/hook-form/RHFRadioGroup';
import Iconify from 'src/components/iconify';
import { useDispatch, useSelector } from 'src/redux/store';
import { updateAgentField } from 'src/utils/agent-field-utils';

interface Params {
  index: number;
  field: AgentFieldLogic;
  darkMode?: boolean;
}

export default function AiAgentUiFieldPublic({ index, field, darkMode }: Params) {
  const [position, setPosition] = useState('flex-start');
  const { setNodeRef } = useDroppable({
    id: index,
  });

 

  useEffect(() => {
    if (
      field.agentFieldConfigurations &&
      !_.isEqual(field.agentFieldConfigurations?.position, position)
    ) {
      const pos = field.agentFieldConfigurations?.position || 'left';
      if (pos === 'left') {
        setPosition('flex-start');
      } else if (pos === 'right') {
        setPosition('flex-end');
      } else if (pos === 'center') {
        setPosition('center');
      }
      setPosition(pos);
    }
  }, [field, position]);



  return (
    <>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            cursor: 'default',

            justifyContent: position,
          }}
        >
          {field.type === 'text' && (
            <Typography
              variant={(field.agentFieldConfigurations?.textType as any) || 'h3'}
              suppressContentEditableWarning
              sx={{
                outline: 'none',

                display: 'inline-block', // Ensures it only takes up as much space as its content
                ':focus': {
                  border: '1px solid #ccc',
                },
                color: darkMode ? 'white' : field.agentFieldConfigurations?.color || 'black',
              }}
              // onInput={(e) => {
              //   debugger;
              //   const updatedField = _.cloneDeep(field) as AgentFieldLogic;
              //   if (!updatedField.agentFieldConfigurations) {
              //     updatedField.agentFieldConfigurations = {
              //       position: 'left',
              //     };
              //   }
              //   updatedField.value = e.currentTarget.innerText;
              //   dispatch(updateAgentField(updatedField as any, false));
              //   //   console.log('onChange', e.target.innerText);
              //   //   handleInput(e);
              // }}
              onBlur={(e) => {
                console.log('onBlur', e.target.innerText);
              
              }}
            >
              {field.value}
            </Typography>
          )}
          {field.type === 'text input' && (
            <TextField
              InputProps={{
                style: {
                  color: darkMode ? 'white' : field.agentFieldConfigurations?.color || 'black',
                }, // Sets the text color to white
              }}
              InputLabelProps={{
                style: {
                  color: darkMode ? 'white' : field.agentFieldConfigurations?.color || 'black',
                }, // Sets the label color to white
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: darkMode
                      ? 'white'
                      : field.agentFieldConfigurations?.color || 'black', // White border
                  },
                  '&:hover fieldset': {
                    borderColor: darkMode
                      ? 'white'
                      : field.agentFieldConfigurations?.color || 'black', // Border color on hover
                  },
                },
              }}
              variant="outlined"
              label={field.value}
            />
          )}
          {field.type === 'radio' && (
            <RHRadioGroup
              value={field.value ?? 'Option 1'} // upload/url
              sx={{
                color: darkMode ? 'white' : field.agentFieldConfigurations?.color || 'black',
              }}
              onChange={(event) => {
                console.log('event.target.value', event.target.value);
               
              }}
              row
              spacing={4}
              name="radio-group-field"
              options={
                (field?.agentFieldConfigurations?.radioOptions as any) ?? [
                  {
                    label: 'Option 1',
                    value: 'Option 1',
                  },
                  {
                    label: 'Option 2',
                    value: 'Option 2',
                  },
                ]
              }
            />
          )}
          {field.type === 'checkbox' && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value === 'true'}
                    onChange={(e) => {
                        console.log('e.target.checked', e.target.checked);
                    
                      // dispatch(updateAgentField(updatedField as any, false));
                    }}
                  />
                }
                label={field.labels ?? 'Placeholder'}
                sx={{
                  color: darkMode ? 'white' : field.agentFieldConfigurations?.color || 'black',
                }}
              />
            </>
          )}
          {field.type === 'upload' && (
            <Button
              fullWidth
              //  disabled={loading || fileName.length > 0}
              //    onClick={openUploadFileDialog.onTrue}
              startIcon={<Iconify icon="mdi:file-upload-outline" />}
              variant="outlined"
              size="large"
              color="inherit"
              sx={{
                mt: 2,
                minWidth: { md: 150 },
                maxHeight: { md: 40 },
              }}
            >
              Upload File
            </Button>
          )}
          {field.type === 'image' && (
            <>
              {field.value && (
                <Box
                  sx={{
                    width: '100%',
                    minWidth: '100%',
                    display: 'flex',
                    justifyContent: position,
                  }}
                >
                  <a
                    href={field.value as string}
                    target="_blank"
                    rel="noopener noreferrer"
                    // optionally style the anchor to remove default underlines, etc.
                    style={{ display: 'inline-block' }}
                  >
                    <img
                      src={field.value as string}
                      alt="URL Preview"
                      style={{
                        maxWidth: `${field?.agentFieldConfigurations?.width}px`,
                        maxHeight: `${field?.agentFieldConfigurations?.height}px`,
                        objectFit: 'contain',
                        cursor: 'pointer',
                        borderRadius: '8px',
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                      }}
                      onError={(e) => {
                        const img = e.target as HTMLImageElement;
                        img.style.display = 'none';
                      }}
                    />
                  </a>
                </Box>
              )}
              {!field.value && (
                <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: position }}>
                  <Paper
                    elevation={1}
                    sx={{
                      width: '100px',
                      height: '100px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'grey.100',
                      border: '2px dashed grey.300',
                    }}
                  >
                    <Typography color="text.secondary">Image</Typography>
                  </Paper>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
