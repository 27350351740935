import { useEffect, useState } from 'react';
import Label from 'src/components/label';
import { useSelector } from 'src/redux/store';

export default function AgentStatus() {
    const agents = useSelector((state) => state.agents.agents);
    const subscriptionTiers = useSelector((state) => state.subscription.tiers);
    const [allowedDeployedAgents, setAllowedDeployedAgents] = useState<number>();
    const [deployedAgents, setDeployedAgents] = useState<number>(0);
    const basicBillingInfo = useSelector((state) => state.subscription.basicBillingInfo);

    useEffect(() => {
        const subscriptionTier = subscriptionTiers.find(
            (tier) => tier.name === basicBillingInfo?.productName
          );
          setDeployedAgents(agents.filter((agent) => agent.published).length);
        if (subscriptionTier && subscriptionTier.deployAgents) {
            setAllowedDeployedAgents(subscriptionTier.deployAgents as number);
        }
    }, [basicBillingInfo, agents, subscriptionTiers]);
  return (
    <>
      <Label color={((deployedAgents ?? 0) as number) < (allowedDeployedAgents ?? 3) ? 'primary' : 'error'}>
        {deployedAgents ?? 0} / {allowedDeployedAgents ?? 3}
        
        {' '}
        deployed agents
      </Label>
    </>
  );
}
