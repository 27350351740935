import { Paper, Box, Avatar, Typography, useTheme } from '@mui/material';
import { keyframes } from '@mui/system';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useSelector } from 'src/redux/store';
import { AgentLogic, DeployedAgentLogic } from 'src/api';
import MessageContent from '../MessageContent';
import { MessageTimestamp } from '../MessageTimestamp';
import { darkModeColor } from './DarkModeColor';

const dotAnimation = keyframes`
  0%, 80%, 100% { opacity: 0.4; transform: scale(0.8); }
  40% { opacity: 1; transform: scale(1); }
`;

interface ChatMessageProps {
  sender?: 'user' | 'assistant';
  content: string;
  timestamp?: Date;
  selectedAgent?: DeployedAgentLogic;
}

export default function ChatMessage({
  sender,
  content,
  timestamp = new Date(),
  selectedAgent,
}: ChatMessageProps) {
  const [showTimestamps, setShowTimestamps] = useState(true);
  const [showAvatars, setShowAvatars] = useState(true);
  const [enableSyntaxHighlighting, setEnableSyntaxHighlighting] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const isProcessing = sender === 'assistant' && content === 'Loading...';
  const theme = useTheme();
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  // color: darkMode?
  // theme.palette.text.secondary :
  // theme.palette.text.primary,

  useEffect(() => {
    setShowTimestamps(
      selectedAgent?.uiConfigurations?.agentChatUiConfigurations?.showTimestamps ?? true
    );
    setDarkMode(selectedAgent?.uiConfigurations?.agentChatUiConfigurations?.darkMode ?? false);
    setShowAvatars(selectedAgent?.uiConfigurations?.agentChatUiConfigurations?.showAvatars ?? true);
    setEnableSyntaxHighlighting(
      selectedAgent?.uiConfigurations?.agentChatUiConfigurations?.syntaxHighlighting ?? true
    );
  }, [selectedAgent]);

  const Dots = () => (
    <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
      {[0, 1, 2].map((i) => (
        <Box
          key={i}
          sx={{
            width: 6,
            height: 6,
            bgcolor: '#10A37F',
            borderRadius: '50%',
            animation: `${dotAnimation} 1.4s infinite ease-in-out`,
            animationDelay: `${i * 0.16}s`,
          }}
        />
      ))}
    </Box>
  );

  // const renderContent = () => {
  //   if (isProcessing) {
  //     return <Dots />;
  //   }

  //   const isCodeBlock = content
  //     .trim()
  //     .match(/^(const|let|var|function|import|class|if|for|while|\{|\[)/);

  //   if (enableSyntaxHighlighting && isCodeBlock) {
  //     return (
  //       <SyntaxHighlighter
  //         language="javascript"
  //         style={vscDarkPlus}
  //         customStyle={{
  //           margin: 0,
  //           padding: '1rem',
  //           borderRadius: '0.5rem',
  //         }}
  //       >
  //         {content}
  //       </SyntaxHighlighter>
  //     );
  //   }

  //   return (
  //     <Typography
  //       sx={{
  //         color: darkMode?
  //           theme.palette.text.secondary :
  //           theme.palette.text.primary,
  //         whiteSpace: 'pre-wrap',
  //         minWidth: 0,
  //       }}
  //     >
  //       {content}
  //     </Typography>
  //   );
  // };

  const calculateBgColor = () => {
    if (sender === 'assistant') {
      return darkMode ? theme.palette.grey[900] : theme.palette.background.paper;
    }

    return darkMode ? theme.palette.grey[800] : theme.palette.action.hover;
  };

  return (
    <Box
      sx={{
        width: '100%',
        py: { xs: 1.5, sm: 2 },
        px: { xs: 1, sm: 2 },
        bgcolor: darkMode ? darkModeColor : null,
        display: 'flex',
        justifyContent: 'center',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: { xs: '100%', sm: '48rem' },
          display: 'flex',
          gap: { xs: 2, sm: 3 },
          alignItems: 'flex-start',
          px: { xs: 2, sm: 0 },
        }}
      >
        {showAvatars && (
          <Avatar
            sx={{
              bgcolor: sender === 'user' ? '#1a7f64' : '#10a37f',
              width: { xs: 24, sm: 30 },
              color: 'white',
              height: { xs: 24, sm: 30 },
              fontSize: { xs: '0.75rem', sm: '0.875rem' },
            }}
          >
            {sender === 'user' ? 'U' : 'A'}
          </Avatar>
        )}
        <Box
          sx={{
            flex: 1,
            '& pre': {
              my: 2,
              p: { xs: 2, sm: 3 },
              borderRadius: 2,
              bgcolor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)',
              overflow: 'auto',
              fontSize: { xs: '0.875rem', sm: '1rem' },
            },
          }}
        >
          {isProcessing ? (
            <>
              <Dots />
              {/* <MessageContent 
              darkMode={darkMode}
                content="I'm thinking about your request..."
                enableSyntaxHighlighting={false}
              />
              <Box sx={{ mt: 2 }}>
                <ProcessingIndicator />
              </Box> */}
            </>
          ) : (
            <MessageContent
              darkMode={darkMode}
              content={content}
              enableSyntaxHighlighting={enableSyntaxHighlighting}
              imageUrls={imageUrls}
            />
          )}
          {showTimestamps && !isProcessing && (
            <MessageTimestamp
            darkMode={darkMode}
            timestamp={timestamp} visible={isHovered} />
          )}
        </Box>
      </Box>
    </Box>
  );
}
