import { Typography } from '@mui/material';
import { m } from 'framer-motion';
import { PageNotFoundIllustration } from 'src/assets/illustrations';
import { MotionContainer, varBounce } from 'src/components/animate';

export default function AgentNotFound() {
  return (
    <>
      <MotionContainer>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Sorry, agent was not found!
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, we couldn’t find the agent you’re looking for. Perhaps you’ve mistyped the URL? Be
            sure to check your spelling.
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <PageNotFoundIllustration
            sx={{
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />
        </m.div>

        
      </MotionContainer>
    </>
  );
}
