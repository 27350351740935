import { yupResolver } from '@hookform/resolvers/yup';
import { MenuItem, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import FormProvider, { RHSelect } from 'src/components/hook-form';
import PreviousActionsSelect from 'src/components/previous-actions/PreviousActionsSelect';
import { setActionValidated, updateSelectedAiFlowItem, updateTempSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';
import { useForm } from 'react-hook-form';

export default function AiFlowTransformerActionSetup() {
  const [selectedType, setSelectedType] = useState<string>('');
  const [selectedFormatFrom, setSelectedFormatFrom] = useState<string>('Csv');
  const [selectedFormatTo, setSelectedFormatTo] = useState<string>('Csv');
  const dispatch = useDispatch();
  const [selectedSource, setSelectedSource] = useState<string>('Text');
  const [selectedTransform, setSelectedTransform] = useState<string>('');
  const [selectedTransformOptions, setSelectedTransformOptions] = useState<any[]>([]);

  const [transformTextOptions, setTransformTextOptions] = useState([
    { label: 'Lowercase', value: 'lowercase' },
    { label: 'Uppercase', value: 'uppercase' },
    { label: 'Capitalize', value: 'capitalize' },
    { label: 'Remove Punctuation', value: 'remove punctuation' },
    { label: 'Remove Words', value: 'remove words' },
    { label: 'Remove Special Characters', value: 'remove special characters' },
    { label: 'Remove Numbers', value: 'remove numbers' },
    { label: 'Remove Spaces', value: 'remove spaces' },
    { label: 'Remove Newlines', value: 'remove newlines' },
    { label: 'Remove Tabs', value: 'remove tabs' },
    { label: 'Remove Whitespace', value: 'remove whitespace' },
  ]);
  // 'Remove URL',
  // 'Remove Email',
  // 'Remove Phone Number',
  // 'Remove Date',
  // 'Remove Time',
  // 'Remove Date Time',
  // 'Remove Currency',
  // 'Remove Number',
  // 'Remove Number with Decimal',
  // const transformTextOptionObjects = transformTextOptions.map(option => ({
  //   value: option,
  //   disabled: !['Lowercase', 'Uppercase', 'Capitalize'].includes(option)
  // }));

  const [transformNumberOptions, setTransformNumberOptions] = useState([
    { label: 'Round', value: 'round' },
    { label: 'Floor', value: 'floor' },
    { label: 'Ceil', value: 'ceil' },
  ]);

  // const transformNumberObjects = transformNumberOptions.map(option => ({
  //   value: option,
  //   disabled: !['Round', 'Floor', 'Ceil'].includes(option)
  // }));

  const [transformDateTimeOptions, setTransformDateTimeOptions] = useState<any[]>([
    { label: 'Add Time', value: 'add_time' },
    { label: 'Parse', value: 'parse' },
    { label: 'Format', value: 'format' },
  ]);

  const [transformFileOptions, setTransformFileOptions] = useState<any[]>([
    { label: 'PDF to Text', value: 'pdf_to_text' },
    { label: 'PDF to Word', value: 'pdf_to_word' },
    { label: 'PDF to Html', value: 'pdf_to_html' },
    { label: 'Word to PDF', value: 'word_to_pdf' },
    { label: 'Html to PDF', value: 'html_to_pdf' },
    { label: 'Excel to Csv', value: 'excel_to_csv' },
    { label: 'Csv to Excel', value: 'csv_to_excel' },
    { label: 'Figma to HTML', value: 'figma_to_html' },
  ]);

  const setSelectedTransformByType = useCallback(
    (type: string) => {
      if (type === 'Text') {
        setSelectedTransformOptions(transformTextOptions);
      } else if (type === 'Number') {
        setSelectedTransformOptions(transformNumberOptions);
      } else if (type === 'DateTime') {
        setSelectedTransformOptions(transformDateTimeOptions);
      } else if (type === 'File') {
        setSelectedTransformOptions(transformFileOptions);
      }
    },
    [transformTextOptions, transformNumberOptions, transformDateTimeOptions, transformFileOptions]
  );

  // const transformDateTimeObjects = transformDateTimeOptions.map(option => ({
  //   value: option,
  //   disabled: !['Add Time', 'Date Format', 'Date Time Format'].includes(option)
  // }));

  const defaultValues = {
    selectedSource: '',
  };

  const AiFlowTransformerSchema: any = Yup.object().shape({
    selectedSource: Yup.string().required('Required'),
  });

  const methods = useForm<any>({
    resolver: yupResolver(AiFlowTransformerSchema),
    mode: 'all',
    defaultValues,
  });

  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.inputData?.actionInputData?.functionalInputData?.transformerInput) {
        setSelectedSource(
          selectedItem.inputData?.actionInputData?.functionalInputData?.transformerInput
            ?.source as string
        );
        methods.setValue(
          'selectedSource',
          selectedItem.inputData?.actionInputData?.functionalInputData?.transformerInput
            ?.source as string
        );
        methods.trigger();
        setSelectedType(
          selectedItem.inputData?.actionInputData?.functionalInputData?.transformerInput
            ?.type as string
        );
        setSelectedTransformByType(
          selectedItem.inputData?.actionInputData?.functionalInputData?.transformerInput
            ?.type as string
        );
        setSelectedTransform(
          selectedItem.inputData?.actionInputData?.functionalInputData?.transformerInput
            ?.transform as string
        );
      }
    }
  }, [selectedItem, methods, setSelectedTransformByType]);

  const {
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
    trigger,
  } = methods;

  useEffect(() => {
    dispatch(setActionValidated(isValid));
  }, [isValid, dispatch]);

  return (
    <>
      <FormProvider methods={methods}>
        <Stack spacing={3} sx={{ mt: 2, mx: 1 }}>
          <PreviousActionsSelect
            selectedSourceInput={selectedSource}
            onPreviousActionsSelect={(source: string) => {
              setSelectedSource(source);
              setValue('selectedSource', source);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    functionalInputData: {
                      ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                      transformerInput: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData
                          ?.transformerInput,
                        source,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
          />
          <RHSelect
            key="type"
            required
            name="type"
            size="small"
            variant="standard"
            value={selectedType}
            onChange={(e) => {
              setSelectedType(e.target.value);
              // Do not nest ternary expressions
              setSelectedTransformByType(e.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    functionalInputData: {
                      ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                      transformerInput: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData
                          ?.transformerInput,
                        type: e.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
            label="Type"
            zIndex={2500}
            InputLabelProps={{ shrink: true }}
            sx={{ px: 2 }}
          >
            <MenuItem value="File">
              <span>File</span>
            </MenuItem>
            <MenuItem value="Text">
              <span>Text</span>
            </MenuItem>
            <MenuItem value="Number">
              <span>Number</span>
            </MenuItem>
            <MenuItem value="DateTime">
              <span>Date/Time</span>
            </MenuItem>
            {/* <MenuItem value="GPT_Table">
              <span>Gpt Table</span>
            </MenuItem> */}
          </RHSelect>

          <RHSelect
            key="transform"
            required
            name="transform"
            size="small"
            variant="standard"
            value={selectedTransform}
            onChange={(e) => {
              setSelectedTransform(e.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    functionalInputData: {
                      ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                      transformerInput: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData
                          ?.transformerInput,
                        transform: e.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
            label="Transform"
            zIndex={2500}
            InputLabelProps={{ shrink: true }}
            sx={{ px: 2 }}
          >
            {selectedTransformOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <span>{option.label}</span>
              </MenuItem>
            ))}
          </RHSelect>
          {/* {selectedType === 'File' && (
            <>
              <RHSelect
                key="formatFrom"
                name="formatFrom"
                size="small"
                variant="standard"
                value={selectedFormatFrom}
                onChange={(e) => {
                  setSelectedFormatFrom(e.target.value);
                  const updatedItem = {
                    ...selectedItem,
                    inputData: {
                      ...selectedItem?.inputData,
                      actionInputData: {
                        ...selectedItem?.inputData?.actionInputData,
                        functionalInputData: {
                          ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                          formatterInput: {
                            ...selectedItem?.inputData?.actionInputData?.functionalInputData
                              ?.formatterInput,
                            transformFrom: e.target.value,
                          },
                        },
                      },
                    },
                  };
                  dispatch(updateTempSelectedAiFlowItem(updatedItem));
                }}
                label="Transform From"
                zIndex={2500}
                InputLabelProps={{ shrink: true }}
                sx={{ px: 2 }}
              >
                <MenuItem value="Text">
                  <span>Text</span>
                </MenuItem>
                <MenuItem value="Csv">
                  <span>Csv</span>
                </MenuItem>
                <MenuItem value="Word">
                  <span>Word</span>
                </MenuItem>
                <MenuItem value="Pdf">
                  <span>Pdf</span>
                </MenuItem>
                <MenuItem value="Excel _Sheet">
                  <span>Excel Sheet</span>
                </MenuItem>

              </RHSelect>

              <RHSelect
                key="formatTo"
                name="formatTo"
                size="small"
                variant="standard"
                value={selectedFormatTo}
                onChange={(e) => {
                  setSelectedFormatFrom(e.target.value);
                  const updatedItem = {
                    ...selectedItem,
                    inputData: {
                      ...selectedItem?.inputData,
                      actionInputData: {
                        ...selectedItem?.inputData?.actionInputData,
                        functionalInputData: {
                          ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                          formatterInput: {
                            ...selectedItem?.inputData?.actionInputData?.functionalInputData
                              ?.formatterInput,
                            transformTo: e.target.value,
                          },
                        },
                      },
                    },
                  };
                  dispatch(updateTempSelectedAiFlowItem(updatedItem));
                }}
                label="Transform To"
                zIndex={2500}
                InputLabelProps={{ shrink: true }}
                sx={{ px: 2 }}
              >
                <MenuItem value="Csv">
                  <span>Csv</span>
                </MenuItem>
                <MenuItem value="Xlsx">
                  <span>Excel Sheet</span>
                </MenuItem>
                <MenuItem value="Word">
                  <span>Word</span>
                </MenuItem>
                <MenuItem value="Pdf">
                  <span>Pdf</span>
                </MenuItem>
                <MenuItem value="Html">
                  <span>Html</span>
                </MenuItem>
              </RHSelect>
            </>
          )} */}
        </Stack>
      </FormProvider>
    </>
  );
}
