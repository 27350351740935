// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { CardProps } from '@mui/material/Card';
import Typography from '@mui/material/Typography';
// assets
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { useState } from 'react';
import { useBoolean } from 'src/hooks/use-boolean';
import { useDispatch, useSelector } from 'src/redux/store';
import { useSnackbar } from '../snackbar';
import { SUBSCRIPTION_ICONS } from 'src/common/constants/icons.constants';
import { StripeApi } from 'src/api';
import { updateSubscriptionPlan } from 'src/redux/slices/subscription';
import { FeatureItem } from 'src/@types/pricing';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

type Props = CardProps & {
  pricingPlans?: any[];

  card: {
    subscription: string;
    price: number;
    caption?: string;
    labelAction?: string;
    lists: FeatureItem[];
    paymentUri: string;
    includeFeaturesOfPlan?: string;
  };
  isYearly?: boolean;
  index: number;
};

export default function PricingCard({ index, card, pricingPlans, isYearly, sx, ...other }: Props) {
  const dispatch = useDispatch();
  const { subscription, price, caption, lists, labelAction, paymentUri, includeFeaturesOfPlan } =
    card;
  const [openedViewMore, setOpenedViewMore] = useState(false);
  const basic = subscription === 'basic';
  const [loadingGenerateLink, setLoadingGenerateLink] = useState(false);
  const advanced = subscription === 'advanced';
  const [startedTrial, setStartedTrial] = useState(false);
  const [finishedTrial, setFinishedTrial] = useState(false);
  const professional = subscription === 'professional';
  const company = subscription === 'company';
  const showMore = useBoolean();
  const loading = useSelector((state) => state.organization.loading);
  const subscriptionLoading = useSelector((state) => state.subscription.loading);
  const basicBillingInfo = useSelector((state) => state.subscription.basicBillingInfo);
  const { enqueueSnackbar } = useSnackbar();
  const renderIcon = (
    <Stack
      sx={{ position: 'relative' }}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box sx={{ width: 48, height: 48, color: '#45CE4E' }}>
        {SUBSCRIPTION_ICONS[subscription as keyof typeof SUBSCRIPTION_ICONS]}
      </Box>

      {/* {professional && (
        <Label color="info" sx={{ position: 'absolute', top: 5 }}>
          Most Popular
        </Label>
      )} */}
    </Stack>
  );

  const renderSubscription = (
    <Stack spacing={1}>
      <Typography variant="h4" sx={{ textTransform: 'capitalize' }}>
        {subscription}
      </Typography>
      <Typography variant="subtitle2">{caption}</Typography>
    </Stack>
  );

  const renderPrice = (
    <Stack direction="row">
      {basic && (
        <Typography variant="h3" sx={{ mb: 2 }}>
          Free
        </Typography>
      )}
      {company && (
        <Typography variant="h3" sx={{ mb: 2 }}>
          Contact Us
        </Typography>
      )}
      {!company && !basic && (
        <>
          <Typography variant="h4">$</Typography>

          <Typography variant="h2">
            {isYearly ? Math.floor(price * 12 - price * 0.12) : price}
          </Typography>

          <Typography
            component="span"
            sx={{ alignSelf: 'center', color: 'text.disabled', ml: 1, typography: 'body2' }}
          >
            {isYearly ? '/ yr' : '/ mo'}
          </Typography>
        </>
      )}
    </Stack>
  );

  const renderList = (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box component="span" sx={{ typography: 'overline' }}>
          Features
        </Box>
        <Link variant="body2" color="inherit" underline="always">
          {includeFeaturesOfPlan && <span>(+{includeFeaturesOfPlan} Features)</span>}
        </Link>
      </Stack>

      {lists.length > 4 && !showMore.value
        ? lists.slice(0, 4).map((item) => (
            <Stack
              key={item.feature}
              spacing={1}
              direction="row"
              justifyContent="start"
              alignItems="center"
              sx={{
                typography: 'body2',
              }}
            >
              <Iconify
                icon={item.notIncluded ? 'octicon:x-24' : 'eva:checkmark-fill'}
                width={16}
                sx={{ marginRight: 2 }}
              />
              <div>{item.feature}</div>
            </Stack>
            // take first 4 elements
          ))
        : lists.map((item) => (
            <Stack
              key={item.feature}
              spacing={1}
              direction="row"
              alignItems="center"
              sx={{
                typography: 'body2',
              }}
            >
              <Iconify
                icon={item.notIncluded ? 'octicon:x-24' : 'eva:checkmark-fill'}
                width={16}
                sx={{ mr: 1 }}
              />
              <div>{item.feature}</div>
            </Stack>
          ))}
      {lists.length > 4 && (
        <Button
          size="small"
          color="inherit"
          startIcon={
            <Iconify
              icon={showMore.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
            />
          }
          onClick={showMore.onToggle}
        >
          {showMore.value ? `Show Less` : `Show More`}
        </Button>
      )}
    </Stack>
  );

  const getPlanStatus = () => {
    const currentProductName = basicBillingInfo?.productName?.toLocaleLowerCase();
    const subscriptionName = subscription.toLocaleLowerCase();
  
    if (currentProductName === subscriptionName) {
      return 'Current Plan';
    }
  
    const currentPlanIndex = pricingPlans?.findIndex(
      (plan) => plan?.name?.toLocaleLowerCase() === currentProductName
    );
  
    return (currentPlanIndex ?? 1) < index ? 'Upgrade' : 'Downgrade';
  }


  return (
    <Stack
      spacing={5}
      sx={{
        display: 'flex', // Add this
        flexDirection: 'column', // Add this
        justifyContent: 'space-between', // Add this
        minHeight: { md: 580 },
        p: 3,
        position: 'relative',
        borderRadius: 2,
        boxShadow: (theme) => ({
          xs: theme.customShadows.card,
        }),
        // ...(starter && {
        //   borderTopRightRadius: { md: 0 },
        //   borderBottomRightRadius: { md: 0 },
        // }),
        // ...((starter || premium) && {
        //   boxShadow: (theme) => ({
        //     xs: theme.customShadows.card,
        //     md: `-40px 40px 80px 0px ${alpha(
        //       theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
        //       0.16
        //     )}`,
        //   }),
        // }),
        ...sx,
      }}
      {...other}
    >
      <Stack spacing={4}>
        {renderIcon}

        {renderSubscription}

        {renderPrice}

        <Divider sx={{ borderStyle: 'dashed' }} />
      </Stack>

      {renderList}
      {labelAction && (
        <LoadingButton
          fullWidth
          loading={loadingGenerateLink || loading ||  subscriptionLoading}
          disabled={
            subscriptionLoading ||
            basicBillingInfo?.productName?.toLocaleLowerCase() === subscription.toLocaleLowerCase()
          }
          size="large"
          onClick={async () => {
            setLoadingGenerateLink(true);
            // open link in a new tab
            if (!basicBillingInfo) {
              const stripeApi = new StripeApi();
              const generatedLinkResponse = await stripeApi.apiStripeGeneratePaymentLinkPost({
                product: subscription,
              });
              setLoadingGenerateLink(false);
              window.location.href = generatedLinkResponse.data;
            } else {
              dispatch(updateSubscriptionPlan(subscription, enqueueSnackbar));
              setLoadingGenerateLink(false);
            }
          }}
          variant="outlined"
          color={
            basicBillingInfo?.productName?.toLocaleLowerCase() === subscription.toLocaleLowerCase()
              ? 'inherit'
              : 'primary'
          }
          // sx={{ position: 'absolute', bottom: 10, right: 32, maxWidth: { md: 200 } }}
        >
          {company && 'Contact Us'}

          {!company && (
            <>
             {getPlanStatus()}
            </>
          )}
        </LoadingButton>
      )}
    </Stack>
  );
}
