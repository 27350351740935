import { Box, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { KnowledgeBaseLogic } from 'src/api';
import { RHSelect } from 'src/components/hook-form';
import { useSelector } from 'src/redux/store';

export interface Props {
  maxWidth?: number;
  onKnowledgeBaseSelect: (kbl: KnowledgeBaseLogic) => void;
  selectedKnowledgeBaseInput?: string | null | undefined;
}

export default function KnowledgeBaseSelect({
  maxWidth,
  onKnowledgeBaseSelect,
  selectedKnowledgeBaseInput,
}: Props) {
  const knowledgeBases = useSelector((state) => state.knowledgeBase.knowledgeBases);

  const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState<string>(
    knowledgeBases[0].name ?? 'My Files Repo'
  );
  return (
    <>
      <Box sx={{ maxWidth, px: 1 }}>
        <RHSelect
          key="selectKnowledgeBase"
          required
          name="selectKnowledgeBase"
          zIndex={3400}
          size="small"
          sx={{ maxWidth: maxWidth as number }}
          label="Select Knowledge Bases"
          value={selectedKnowledgeBaseInput ?? selectedKnowledgeBase}
          onChange={(event) => {
            setSelectedKnowledgeBase(event.target.value);
            const tmpKb = knowledgeBases.find((kb) => kb.name === event.target.value);
            onKnowledgeBaseSelect(tmpKb as KnowledgeBaseLogic);
          }}
          InputLabelProps={{ shrink: true }}
          // sx={{ maxWidth: { md: 180 } }}
        >
          {knowledgeBases.map((knowledgeBase) => (
            <MenuItem value={knowledgeBase.name as string}>
              <span>{knowledgeBase.name}</span>
            </MenuItem>
          ))}
        </RHSelect>
      </Box>
    </>
  );
}
