import { Typography, useTheme, Box } from '@mui/material';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import RenderHTML from 'src/utils/renderHtml';

interface MessageContentProps {
  content: string;
  darkMode: boolean;
  enableSyntaxHighlighting?: boolean;
  imageUrls?: string[];
}

export default function MessageContent({
  content,
  darkMode,
  enableSyntaxHighlighting = true,
  imageUrls,
}: MessageContentProps) {
  const theme = useTheme();
  const isCodeBlock = content
    ?.trim()
    .match(/^(const|let|var|function|import|class|if|for|while|\{|\[)/);

  const processAnnotations = (text: string): any => {
    let counter = 1;
    return text.replace(
      /\[\*([^*]+)\*\]/g,
      () => `<sup><span title="Reference ${counter}">&nbsp;${counter++}&nbsp;</span></sup>`
    );
  };

  // useEffect(() => {

  //   const processedContent = processAnnotations(content);
  //   setContent(processedContent);

  // }, [content]);

  return (
    <>
      {imageUrls && imageUrls.length > 0 && (
        <Box
          sx={{
            mb: 2,
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(auto-fill, minmax(140px, 1fr))',
              sm: 'repeat(auto-fill, minmax(200px, 1fr))',
            },
            gap: { xs: 1, sm: 2 },
          }}
        >
          {imageUrls.map((url, index) => (
            <img
              key={index}
              src={url}
              alt={`Uploaded content ${index + 1}`}
              style={{
                width: '100%',
                maxHeight: '400px',
                borderRadius: '8px',
                objectFit: 'contain',
              }}
            />
          ))}
        </Box>
      )}
      {enableSyntaxHighlighting && isCodeBlock ? (
        <SyntaxHighlighter
          language="javascript"
          style={vscDarkPlus}
          customStyle={{
            margin: '16px 0',
            padding: '16px',
            borderRadius: '8px',
            backgroundColor:
              theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)',
            fontSize: window.innerWidth < 640 ? '0.875rem' : '1rem',
          }}
        >
          {content}
        </SyntaxHighlighter>
      ) : (
        <ReactMarkdown
          components={{
            p: ({ children }) => (
              <Typography
                component="p"
                sx={{
                  color: darkMode ? 'white' : theme.palette.text.primary,
                  whiteSpace: 'pre-wrap',
                  minWidth: 0,
                  lineHeight: 1.75,
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  '& code': {
                    backgroundColor:
                      theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)',
                    padding: '2px 4px',
                    borderRadius: '4px',
                    fontFamily: 'monospace',
                  },
                }}
              >
                {children}
              </Typography>
            ),

            li: ({ children }) => (
              <Typography 
                component="li"
                sx={{ 
                  color: darkMode ? 'white' : theme.palette.text.primary,
             //     whiteSpace: "pre-wrap",
                  minWidth: 0,
                   lineHeight: 1.75,
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  '& code': {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)',
                    padding: '2px 4px',
                    borderRadius: '4px',
                    fontFamily: 'monospace'
                  }
                }}
              >
                {children}
              </Typography>
            ),
            // Here is where we specifically process annotations for `li` items
            // li: ({ children }) => {
            //   // Convert children to plain text
            //   const liText = React.Children.toArray(children).join('');
            //   // Process annotations in list item text
            //   const processedLiText = processAnnotations(liText);

            //   return (
            //     <Typography
            //       component="li"
            //       // We use dangerouslySetInnerHTML to preserve the <sup> tag
            //       dangerouslySetInnerHTML={{ __html: processedLiText }}
            //       sx={{
            //         color: darkMode ? 'white' : theme.palette.text.primary,
            //         minWidth: 0,
            //         lineHeight: 1.75,
            //         fontSize: { xs: '0.875rem', sm: '1rem' },
            //         '& code': {
            //           backgroundColor:
            //             theme.palette.mode === 'dark'
            //               ? 'rgba(255,255,255,0.1)'
            //               : 'rgba(0,0,0,0.05)',
            //           padding: '2px 4px',
            //           borderRadius: '4px',
            //           fontFamily: 'monospace',
            //         },
            //       }}
            //     />
            //   );
            // },
            h3: ({ children }) => (
              <Typography
                component="h3"
                sx={{
                  fontWeight: 600,
                  color: darkMode ? 'white' : theme.palette.text.primary,
                }}
              >
                {children}
              </Typography>
            ),
            strong: ({ children }) => (
              <Typography
                component="strong"
                sx={{
                  fontWeight: 600,
                  color: darkMode ? 'white' : theme.palette.text.primary,
                }}
              >
                {children}
              </Typography>
            ),
            code: ({ children }) => (
              <Typography
                component="code"
                sx={{
                  backgroundColor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)',
                  padding: '2px 4px',
                  borderRadius: '4px',
                  fontFamily: 'monospace',
                  fontSize: '0.9em',
                }}
              >
                {children}
              </Typography>
            ),
          }}
        >
          {content}
        </ReactMarkdown>
      )}
    </>
  );
}
