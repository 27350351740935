import { EdgeProps, getBezierPath } from '@xyflow/react';

import useEdgeClick from '../hooks/useEdgeClick';
import styles from './EdgeTypes.module.css';
import { useSelector } from 'src/redux/store';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
}: EdgeProps) {
  // see the hook for implementation details
  // onClick adds a node in between the nodes that are connected by this edge
  const onClick = useEdgeClick(id);
  const targetId = id.split('->')[1];
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const agentSetupMode = useSelector((state) => state.appStates.agentSetupMode);
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const itemByTarget = selectedAiFlow?.items?.find((item) => item.sequence === Number(targetId));
  const [edgePath, edgeCenterX, edgeCenterY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path id={id} style={style} className={styles.edgePath} d={edgePath} markerEnd={markerEnd} />
      {!agentSetupMode && !runsMode && itemByTarget?.header !== "Branch" && ((targetId.includes('bc') || targetId.includes('b') || (selectedAiFlow?.items?.length ?? 0) >= Number(targetId))) &&  (
        <g transform={`translate(${edgeCenterX}, ${edgeCenterY})`}>
          <rect
            onClick={onClick}
            x={-10}
            y={-10}
            width={20}
            ry={4}
            rx={4}
            height={20}
            className={styles.edgeButton}
          />
          <text className={styles.edgeButtonText} y={5} x={-4}>
            +
          </text>
        </g>
      )}
    </>
  );
}
