import { Box, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { KnowledgeBaseFileLogic, KnowledgeBaseLogic } from 'src/api';
import {
  EmbeddingsModelType,
  FineTuneModelType,
  ModelType,
  embeddingsLlmModels,
  fineTuneLlmModels,
  llmModels,
} from 'src/common/constants/llm-models.constants';
import { RHSelect } from 'src/components/hook-form';
import { useSelector } from 'src/redux/store';

export interface Props {
  maxWidth?: number;
  onKnowledgeBaseFileSelect: (knowledgeBaseFile: string) => void;
  selectedKnowledgeBaseFileInput?: string | null | undefined;
  knowledgeBaseFiles: KnowledgeBaseFileLogic[];
}

export default function KnowledgeBaseFileSelect({
  maxWidth,
  onKnowledgeBaseFileSelect,
  knowledgeBaseFiles,
  selectedKnowledgeBaseFileInput,
}: Props) {
  const [selectedKnowledgeBaseFile, setSelectedKnowledgeBaseFile] = useState<string>(
    (knowledgeBaseFiles?.length > 0 ?  knowledgeBaseFiles[0].name : '')  ?? ''
  );

  return (
    <>
      <Box sx={{ maxWidth, px: 1 }}>
        <RHSelect
          key="selectKnowledgeBaseFile"
          name="selectKnowledgeBaseFile"
          zIndex={3400}
          size="small"
          required
          sx={{ maxWidth: maxWidth as number }}
          label="Select File"
          value={selectedKnowledgeBaseFileInput ?? selectedKnowledgeBaseFile}
          onChange={(event) => {
            setSelectedKnowledgeBaseFile(event.target.value);
            onKnowledgeBaseFileSelect(event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          // sx={{ maxWidth: { md: 180 } }}
        >
          {knowledgeBaseFiles.map((kbf) => (
            <MenuItem value={kbf.name as string}>
              <span>{kbf.name}</span>
            </MenuItem>
          ))}
        </RHSelect>
      </Box>
    </>
  );
}
