import React, { useState, useEffect, useRef } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import packageInfo from 'pdfjs-dist/package.json';

interface Props {
  pdfUrl: string;
}

export default function PdfViewer({ pdfUrl }: Props) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const pdfjsVersion = packageInfo.version; // Get the version dynamically
  const pdfViewerRef = useRef<any>(null); // Reference to the PDF viewer container
  console.log('pdfUrl', pdfUrl);

  //  useEffect(() => {
  //   if (pdfUrl && pdfViewerRef.current) {
  // Trigger full-screen mode
  //      if (pdfViewerRef.current.requestFullscreen) {
  //          pdfViewerRef.current.requestFullscreen();
  //       } else if (pdfViewerRef.current.mozRequestFullScreen) { /* Firefox */
  //            pdfViewerRef.current.mozRequestFullScreen();
  //        } else if (pdfViewerRef.current.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
  //            pdfViewerRef.current.webkitRequestFullscreen();
  //         } else if (pdfViewerRef.current.msRequestFullscreen) { /* IE/Edge */
  //             pdfViewerRef.current.msRequestFullscreen();
  //        }
  //    }
  // }, [pdfUrl]);

  return (
    <div ref={pdfViewerRef} style={{ height: '100%', width: '100%', zIndex: 9500, scale: 1 }}>
      {pdfUrl ? (
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
          <Viewer
         //   initialPage={currentPage}
            // onPageChange={(page) => {
            //   console.log('page', page);
            //   setCurrentPage(page.currentPage);
            // }}
          //  defaultScale={1}
            fileUrl={pdfUrl}
            plugins={[defaultLayoutPluginInstance]}
          />
        </Worker>
      ) : (
        <p>No PDF file selected</p>
      )}
    </div>
  );
}
