import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IInitialSetupState } from 'src/@types/initial-setup';
// @types
import { InitialSetupApi, InitialSetupStatusLogic } from 'src/api';

// ----------------------------------------------------------------------

const initialState: IInitialSetupState = {
  error: null,
  initialSetupStatus: null,
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'initial-setup',
  initialState,
  reducers: {
    fetchInitialSetupStatusSuccess(state, action) {
      state.loading = false;
      state.initialSetupStatus = action.payload;
      state.loaded = true;
    },
    updateInitialSetupStatusSuccess(state, action) {
      state.loading = false;
      state.initialSetupStatus = action.payload;
      state.loaded = true;
    },
    updateInitialSetupStatusExploreTemplatesSuccess(state) {
      state.initialSetupStatus = {
        ...state.initialSetupStatus,
      };
      state.loading = false;
      state.loaded = true;
    },
    updateInitialSetupStatusVisitHowToSuccess(state) {
      state.initialSetupStatus = {
        ...state.initialSetupStatus,
      };
      state.loading = false;
      state.loaded = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function fetchInitialSetupStatus() {
  return async (dispatch: Dispatch) => {
    try {
      const initialStatusApi = new InitialSetupApi();
      const response = await initialStatusApi.apiInitialSetupInitialSetupStatusGet();
      dispatch(slice.actions.fetchInitialSetupStatusSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateInitialSetupStatus(initialSetupStatus: InitialSetupStatusLogic) {
  return async (dispatch: Dispatch) => {
    try {
      const initialStatusApi = new InitialSetupApi();
      await initialStatusApi.apiInitialSetupUpdateInitialSetupPut({ initialSetupStatus });
      dispatch(slice.actions.updateInitialSetupStatusSuccess(initialSetupStatus));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function updateInitialSetupStatusExploreTemplates() {
//   return async (dispatch: Dispatch) => {
//     try {
//       const initialStatusApi = new InitialSetupApi();
//       await initialStatusApi.apiInitialSetupUpdateInitialSetupExploreTemplatesPut();
//       dispatch(slice.actions.updateInitialSetupStatusExploreTemplatesSuccess());
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function updateInitialSetupStatusVisitHowTo() {
//   return async (dispatch: Dispatch) => {
//     try {
//       const initialStatusApi = new InitialSetupApi();
//       await initialStatusApi.apiInitialSetupUpdateInitialSetupVisitHowtoPut();
//       dispatch(slice.actions.updateInitialSetupStatusVisitHowToSuccess());
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

