import { keyframes } from '@emotion/react';
import { Box, styled } from '@mui/material';

const dotAnimation = keyframes`
  0%, 20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Dot = styled('span')`
  opacity: 0;
  animation: ${dotAnimation} 1.4s infinite;
  animation-fill-mode: both;
  font-size: inherit;

  &:nth-of-type(2) {
    animation-delay: 0.2s;
  }

  &:nth-of-type(3) {
    animation-delay: 0.4s;
  }
`;

const LoadingDots = () => (
  <Box component="span" sx={{ display: 'inline-flex', gap: 0.5 }}>
    <Dot>.</Dot>
    <Dot>.</Dot>
    <Dot>.</Dot>
  </Box>
);

export default LoadingDots;
