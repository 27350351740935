// @mui
import { Box, Skeleton, Grid, GridProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function SkeletonAgent({ height, ...other }: GridProps) {
  return (
    <Grid item xs={12} sx = {{marginTop:2}} {...other}>
      <Skeleton
        variant="rectangular"
        sx={{ height: height ?? "90vh", flexGrow:1,  mx: 3,mt:1, borderRadius: 2 }}
      />
    </Grid>
  );
}
