import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { AgentApi, DeployedAgentLogic } from 'src/api';
import Page404 from '../Page404';
import AgentChatUiPublic from './chat/public/AgentChatUiPublic';
import { SkeletonProductDetails } from 'src/components/skeleton';
import SkeletonAiFlow from 'src/components/skeleton/SkeletonAiFlow';
import SkeletonAgent from 'src/components/skeleton/SkeletonAgent';
import AgentFormUiPublic from './form/AgentFormUiPublic';

interface Params {
  deployedAgentName?: string;
}

export default function AgentUiPublic({ deployedAgentName }: Params) {
  const [agentNotFound, setAgentNotFound] = useState<boolean>(false);
  const [selectedAgent, setSelectedAgent] = useState<DeployedAgentLogic>();
  const [agentLoaded, setAgentLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (deployedAgentName) {
      const getDepAgent = async () => {
        const agentApi = new AgentApi();
        try {
          const depAgent = await agentApi.apiAgentAgentByNameDeployedAgentNameGet(
            deployedAgentName
          );
          if (!depAgent.data) {
            // navigate to not found page
            setAgentNotFound(true);
          }
          setSelectedAgent(depAgent.data);
          console.log('depAgent', depAgent.data);
          setAgentLoaded(true);
        } catch (error) {
          // navigate to not found page
          setAgentNotFound(true);
        }
      };
      getDepAgent();
    }
  }, [deployedAgentName]);

  return agentLoaded ? (
    <Box>
      {agentNotFound ? (
        <Page404 />
      ) : (
        <>
          {selectedAgent?.interfaceType === 'chat' && (
            <AgentChatUiPublic deployedAgent={selectedAgent} />
          )}
          {(!selectedAgent?.interfaceType || selectedAgent?.interfaceType === 'form') && (
            <AgentFormUiPublic deployedAgent={selectedAgent as any} />
          )}
        </>
      )}
    </Box>
  ) : (
    <SkeletonAgent />
  );
}
