import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
// export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const LoginPage = Loadable(lazy(() => import('../pages/auth/modern/modern-login-page')));
export const LoginCallbackPage = Loadable(lazy(() => import('../pages/auth/LoginCallbackPage')));
export const OAuthCallbackPage = Loadable(lazy(() => import('../pages/auth/OAuthCallbackPage')));
// export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/modern/modern-register-view')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// DASHBOARD: GENERAL
export const DashboardPage = Loadable(lazy(() => import('../pages/dashboard/DashboardPage')));

// Email Marketing
export const NewCampaignPage = Loadable(
  lazy(() => import('../pages/marketing/campaign/NewCampaignPage'))
);
export const UserPage = Loadable(
  lazy(() => import('../pages/account/UserPage'))
);
export const TeamPage = Loadable(
  lazy(() => import('../pages/team/TeamPage'))
);
export const MyEmailTemplatesPage = Loadable(
  lazy(() => import('../pages/marketing/templates/MyEmailTemplatesPage'))
);
export const EditTemplatePage = Loadable(
  lazy(() => import('../pages/marketing/templates/EditTemplatePage'))
);
export const AudiencePage = Loadable(
  lazy(() => import('../pages/marketing/audience/AudiencePage'))
);
export const AnalyticsPage = Loadable(
  lazy(() => import('../pages/analytics/AnalyticsPage'))
);
export const EmailVerifyPage = Loadable(
  lazy(() => import('../pages/account/EmailVerifyPage'))
);
export const NewAccountEmailVerifyPage = Loadable(
  lazy(() => import('../pages/account/NewAccountEmailVerifyPage'))
);
export const PasswordVerifyPage = Loadable(
  lazy(() => import('../pages/account/PasswordVerifyPage'))
);
// export const GeneratedContentPage = Loadable(
//   lazy(() => import('../pages/marketing/outputs/OutputsPage_old'))
// );
export const EditGeneratedContentPage = Loadable(
  lazy(() => import('../pages/marketing/content/generated/EditGeneratedContentPage'))
);
export const PersonasPage = Loadable(lazy(() => import('../pages/marketing/persona/PersonasPage')));
export const PerformancePage = Loadable(
  lazy(() => import('../pages/marketing/performance/PerformancePage'))
);

export const TemplatesPage = Loadable(
  lazy(() => import('../pages/templates/TemplatesPage'))
);

export const PromptsPage = Loadable(
  lazy(() => import('../pages/prompts/PromptsPage'))
);

export const PromptRepositoriesPage = Loadable(
  lazy(() => import('../pages/prompts/PromptRepositoriesPage'))
);

export const KnowledgeBasesPage = Loadable(
  lazy(() => import('../pages/knowledge-base/KnowledgeBasesPage'))
);


// Actions
export const TranslatePage = Loadable(
  lazy(() => import('../pages/marketing/actions/TranslationPage'))
);
export const RefinePage = Loadable(lazy(() => import('../pages/marketing/actions/RefinePage')));
export const TailorPage = Loadable(lazy(() => import('../pages/marketing/actions/TailorPage')));

export const GenerateEmailPage = Loadable(
  lazy(() => import('../pages/marketing/actions/email-content/GenerateEmailPage'))
);

export const GeneratePromptsPage = Loadable(
  lazy(() => import('../pages/marketing/actions/GeneratePromptsPage'))
);

// export const GenerateImagePage = Loadable(
//   lazy(() => import('../pages/marketing/actions/GenerateImagePage'))
// );

export const AiAgentsPage = Loadable(
  lazy(() => import('../pages/agents/AgentsPage'))
);

export const AiAgentSetupPage = Loadable(
  lazy(() => import('../pages/agents/AgentSetupPage'))
);

export const AiAgentUsePage = Loadable(
  lazy(() => import('../pages/agents/AgentUsePage'))
);

export const AiAssistantsPage = Loadable(
  lazy(() => import('../pages/assistants/AssistantsPage'))
);


export const GenerateSmsPage = Loadable(
  lazy(() => import('../pages/marketing/actions/sms-content/GenerateSmsPage'))
);

export const AnalyseEmailPage = Loadable(
  lazy(() => import('../pages/marketing/actions/TextAnalysisPage'))
);

export const AiFlowsPage = Loadable(
  lazy(() => import('../pages/aiFlows/AiFlowsPage'))
);

export const AiFlowsRunsPage = Loadable(
  lazy(() => import('../pages/aiFlow-runs/AiFlowRunsPage'))
);

export const EditAiFlowPage = Loadable(
  lazy(() => import('../pages/aiFlows/EditAiFlowPage'))
);

export const AiFlowPage = Loadable(
  lazy(() => import('../pages/aiFlows/AiFlowPage'))
);

export const ActionsPage = Loadable(lazy(() => import('../pages/marketing/actions/ActionsPage')));

export const NewPersonaPage = Loadable(
  lazy(() => import('../pages/marketing/persona/NewPersonaPage'))
);

export const EditPersonaPage = Loadable(
  lazy(() => import('../pages/marketing/persona/EditPersonaPage'))
);

export const ContentPage = Loadable(lazy(() => import('../pages/marketing/content/ContentPage')));

export const CampaignsPage = Loadable(
  lazy(() => import('../pages/marketing/campaign/CampaignsPage'))
);

export const OutputsPage = Loadable(
  lazy(() => import('../pages/outputs/OutputsPage'))
);

export const CustomActionsPage = Loadable(
  lazy(() => import('../pages/custom-actions/MyActionsPage'))
);

export const HowToPage = Loadable(
  lazy(() => import('../pages/how-to/HowToPage'))
);

export const ExpressionsPage = Loadable(
  lazy(() => import('../pages/marketing/expressions/ExpressionsPage'))
);


export const EditCampaignPage = Loadable(
  lazy(() => import('../pages/marketing/campaign/EditCampaignPage'))
);

export const SavedConfigurationPage = Loadable(
  lazy(() => import('../pages/configurations/ConfigurationsPage'))
);

export const GenerateEmailIdeasPage = Loadable(
  lazy(() => import('../pages/marketing/actions/ideas/GenerateIdeasPage'))
);

export const ContentAnalysisPage = Loadable(
  lazy(() => import('../pages/marketing/actions/TextAnalysisPage'))
);


// DASHBOARD: BLOG
// export const ContentGeneratePage = Loadable(lazy(() => import('../pages/marketing/content/generate/ContentGeneratePage')));

// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/BlankPage')));

// MAIN
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
